import axiosInstance, {
  ACTIVE_CALLS_URL,
  RECENT_CALLS_URL,
  MISSED_CALLS_URL,
  ACTIVE_ROOMS_URL,
  ADD_CHANNEL_URL,
  START_CALL_ROCERDING_URL,
  STOP_CALL_ROCERDING_URL,
  MUTE_CALL_URL,
  UNMUTE_CALL_URL,
  HANGUP_CALL_URL,
  REMOVE_CHANNEL_URL,
  SEND_CALL_DTMF_URL,
  GENERATE_CALLER_NUMBER_URL,
  MAKE_CALL_URL,
  PAUSE_RECORDING_URL,
  RESUME_RECORDING_URL,
  MUTE_RECORDING_URL,
  UNMUTE_RECORDING_URL,
  WEBRTC_USERS,
  GET_SESSION_URL,
  CREATE_SESSION_URL,
  LIST_DIDS_URL,
  DELETE_ROOMS_URL,
  OUTREACH_FLAG,
} from "./config.js";

export default {
  activeCalls() {
    return axiosInstance.get(ACTIVE_CALLS_URL);
  },
  recentCalls() {
    return axiosInstance.get(RECENT_CALLS_URL);
  },
  missedCalls() {
    return axiosInstance.get(MISSED_CALLS_URL);
  },
  activeRooms() {
    return axiosInstance.get(ACTIVE_ROOMS_URL);
  },
  webrtcCalls() {
    return axiosInstance.get(WEBRTC_USERS);
  },
  listDIDs(outreach) {
    let params = "";
    if (outreach) {
      params = `outreach=${OUTREACH_FLAG}`;
    }
    return axiosInstance.get(`${LIST_DIDS_URL}?${params}`);
  },
  createRoom(bridgeId, roomName, roomType) {
    return axiosInstance.post(`${ACTIVE_ROOMS_URL}`, {
      bridgeId: bridgeId,
      type: roomType,
      name: roomName,
    });
  },
  deleteRoom(bridgeId) {
    return axiosInstance.delete(
      `${DELETE_ROOMS_URL.replace("{roomId}", bridgeId)}`
    );
  },

  addChannel(roomId, channelId) {
    return axiosInstance.post(
      `${ADD_CHANNEL_URL.replace("{roomId}", roomId)}`,
      {
        channelId: channelId,
      }
    );
  },
  removeChannel(roomId, channelId) {
    return axiosInstance.post(
      `${REMOVE_CHANNEL_URL.replace("{roomId}", roomId)}`,
      {
        channelId: channelId,
      }
    );
  },

  loadRoomDetails(roomId) {
    return axiosInstance.get(`${ACTIVE_ROOMS_URL}/${roomId}`);
  },

  startRecording(callId) {
    return axiosInstance.post(
      `${START_CALL_ROCERDING_URL.replace("{callId}", callId)}`
    );
  },
  stopRecording(callId) {
    return axiosInstance.post(
      `${STOP_CALL_ROCERDING_URL.replace("{callId}", callId)}`
    );
  },

  muteCall(callId) {
    return axiosInstance.post(`${MUTE_CALL_URL.replace("{callId}", callId)}`);
  },
  unMuteCall(callId) {
    return axiosInstance.post(`${UNMUTE_CALL_URL.replace("{callId}", callId)}`);
  },

  pauseRecording(callId) {
    return axiosInstance.post(
      `${PAUSE_RECORDING_URL.replace("{callId}", callId)}`
    );
  },
  resumeRecording(callId) {
    return axiosInstance.post(
      `${RESUME_RECORDING_URL.replace("{callId}", callId)}`
    );
  },

  muteRecording(callId) {
    return axiosInstance.post(
      `${MUTE_RECORDING_URL.replace("{callId}", callId)}`
    );
  },
  unmuteRecording(callId) {
    return axiosInstance.post(
      `${UNMUTE_RECORDING_URL.replace("{callId}", callId)}`
    );
  },

  hangupCall(callId) {
    return axiosInstance.post(`${HANGUP_CALL_URL.replace("{callId}", callId)}`);
  },

  sendDTMF(callId, dtmf) {
    return axiosInstance.post(
      `${SEND_CALL_DTMF_URL.replace("{callId}", callId)}`,
      {
        dtmf: dtmf,
      }
    );
  },

  generateCalledNumber(number) {
    return axiosInstance.get(`${GENERATE_CALLER_NUMBER_URL}${number}`);
  },

  placeCall(
    number,
    callerIdNumber,
    callerIdName,
    bridgeId,
    local,
    silent_join,
    record = false
  ) {
    return axiosInstance.post(`${MAKE_CALL_URL}`, {
      number,
      callerIdNumber,
      callerIdName,
      bridgeId,
      record: record,
      local: local,
      silent_join,
    });
  },

  getActiveSession() {
    return axiosInstance.get(GET_SESSION_URL);
  },

  createSession() {
    return axiosInstance.post(CREATE_SESSION_URL, {});
  },
};
