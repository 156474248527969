<template>
  <button
    type="button"
    class="
      bg-white
      rounded-full
      flex
      text-sm
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600
    "
    id="menu-1"
    aria-expanded="false"
    aria-haspopup="true"
    @click="menuOpened = !menuOpened"
  >
    <span class="sr-only">Open user menu</span>
    <img
      id="profile-menu"
      class="h-8 w-8 rounded-full"
      :src="`https://ui-avatars.com/api/?name=${userName}&background=1F2937&color=FFF`"
      alt=""
    />
  </button>

  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div
      v-if="menuOpened"
      class="
        origin-top-right
        absolute
        z-30
        right-0
        mt-2
        w-56
        rounded-md
        shadow-lg
        bg-white
        ring-1 ring-black ring-opacity-5
        focus:outline-none
      "
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-1"
    >
      <div class="py-1" role="none">
        <a
          href="#"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem"
        >
          Your Profile
        </a>
        <button
          class="
            block
            px-4
            py-2
            text-sm text-gray-700
            hover:bg-gray-100
            w-full
            text-left
          "
          role="menuitem"
          @click="logout"
        >
          Sign Out
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
import StorageUtil from "@/utils/LocalStorageUtil";

export default {
  data() {
    return {
      menuOpened: false,
    };
  },
  methods: {
    close(e) {
      if (e.target.id !== "profile-menu") {
        this.menuOpened = false;
      }
    },
    ...mapActions("auth", ["logout"]),
  },
  computed: {
    userName() {
      return StorageUtil.getUserData()?.name;
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style></style>
