<template>
  <vue-progress-bar></vue-progress-bar>
  <router-view />
</template>

<script>
import StorageUtil from "@/utils/LocalStorageUtil.js";
import axiosInstance from "@/network/config.js";
import { mapActions, mapGetters } from "vuex";
import { useSnackbarPlugin } from "snackbar-vue";
//import "ion-sound";

export default {
  setup() {
    const snack = useSnackbarPlugin();

    const primary = (props) => {
      snack.show(props);
    };

    const success = (props) => {
      snack.success(props);
    };

    const danger = (props) => {
      snack.danger(props);
    };

    return { primary, success, danger };
  },

  async created() {
    this.initPusher();
    this.pusherBind();
    this.subscribeToChannel();
    const userString = StorageUtil.getUserAuthData();
    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("auth/SET_AUTH_DATA", userData);
      if (userData.user_type !== "listen_only") {
        this.initRecordingPusher();
        this.pusherRecordingBind();
        this.subscribeToRecordingChannel();
        this.initSMSPusher();
        this.pusherSMSBind();
        this.subscribeToSMSChannel();
      }
    }

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        return Promise.reject(error);
      }
    );
  },

  methods: {
    ...mapActions("activeCalls", [
      "initPusher",
      "pusherBind",
      "subscribeToChannel",
    ]),
    ...mapActions("RecordingsStore", [
      "initRecordingPusher",
      "pusherRecordingBind",
      "subscribeToRecordingChannel",
    ]),
    ...mapActions("smsStore", [
      "initSMSPusher",
      "pusherSMSBind",
      "subscribeToSMSChannel",
    ]),
  },
  computed: {
    ...mapGetters("channels", ["pusher"]),
    ...mapGetters("snackbarStore", ["snackbarProps"]),
  },
  watch: {
    snackbarProps: {
      handler: function (newValue) {
        if (newValue) {
          switch (newValue.type) {
            case "default":
              this.primary(newValue);
              break;
            case "success":
              this.success(newValue);
              break;
            case "danger":
              this.danger(newValue);
              break;
          }
          // const snackProps = {
          //   position: "bottom",
          //   text: newValue.message,
          //   close: true,
          //   time: 5000,
          //   button: "Go To Session",
          //   action: () => {
          //     window.open(newValue.url);
          //   },
          // };
          // this.success(snackProps);
        }
      },
    },
  },
};
</script>
