export default class Utils {
  static setUserAuthData = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
  };

  static removeUserAuthData = () => {
    localStorage.removeItem("user");
  };

  static getUserAuthData = () => localStorage.getItem("user");

  static getUserAuthToken = () => {
    const jsonData = JSON.parse(localStorage.getItem("user"));
    if (jsonData) {
      return jsonData["token"];
    }
  };

  static getUserType = () => {
    const jsonData = JSON.parse(localStorage.getItem("user"));
    if (jsonData) {
      return jsonData["data"]["user_type"];
    }
  };

  static getUserData = () => {
    const jsonData = JSON.parse(localStorage.getItem("user"));
    if (jsonData) {
      return jsonData["data"];
    }
  };
}
