import axiosInstance, { UPDATE_CALL_NOTES_URL } from "../config.js";

export default {
  updateNote(callId, note) {
    return axiosInstance.patch(
      `${UPDATE_CALL_NOTES_URL.replace("{callId}", callId)}`,
      {
        notes: note,
      }
    );
  },
};
