import axiosInstance, {
  LIST_CONTACTS_URL,
  SEND_SMS_URL,
  MARK_SMS_AS_SEEN_URL,
  UPLOAD_MMS_FILE,
  RESTORE_SMS_URL,
  DELETE_SMS_URL,
} from "../config.js";
import StorageUtil from "@/utils/LocalStorageUtil";
import { post } from "axios";

function getQueryString(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}

export default {
  listContacts(phone_number, outreach) {
    let contactsURL = LIST_CONTACTS_URL;
    let params = {};
    if (phone_number) params["phone_number"] = encodeURIComponent(phone_number);
    if (outreach !== null) params["outreach"] = outreach;
    const qs = getQueryString(params);
    if (qs) contactsURL += `?${qs}`;
    return axiosInstance.get(contactsURL);
  },

  sendSMS(did_number, destination_number, message_body, media_urls) {
    return axiosInstance.post(`${SEND_SMS_URL}`, {
      did_number,
      destination_number,
      message_body,
      media_urls,
    });
  },

  uploadMMSFile(formData) {
    const url = `${process.env.VUE_APP_BACKEND_URL}${UPLOAD_MMS_FILE}`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${StorageUtil.getUserAuthToken()}`,
      },
    };
    return post(url, formData, config);
  },

  markAsSeen(did_number, phone_number) {
    return axiosInstance.post(`${MARK_SMS_AS_SEEN_URL}`, {
      did_number,
      phone_number,
    });
  },

  deleteMessage(id) {
    return axiosInstance.delete(`${DELETE_SMS_URL}/${id}`);
  },

  restoreMessage(id) {
    return axiosInstance.post(RESTORE_SMS_URL, {
      id,
    });
  },
};
