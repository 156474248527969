<template>
  <div class="min-w-0 flex-1 flex items-center justify-between">
    <div class="min-w-0 flex-1 ml-4">
      <h2
        class="
          text-2xl
          font-bold
          leading-7
          text-gray-900
          sm:text-2xl sm:truncate
        "
      >
        {{ title }}
      </h2>
      <!-- <button @click="joinWebRtc">ward</button> -->
    </div>
    <WebRtc />
    <div class="ml-4 pr-4 flex-shrink-0 flex items-center space-x-10">
      <div class="flex items-center space-x-8">
        <NotificationSoundMenu />
        <span class="inline-flex">
          <a
            href="#"
            class="
              -mx-1
              bg-white
              p-1
              rounded-full
              text-gray-400
              hover:text-gray-500
            "
          >
            <span class="sr-only">View notifications</span>
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#ffa30c"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </a>
        </span>

        <div class="relative inline-block text-left">
          <ProfileMenu />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileMenu from "@/widgets/topbar/ProfileMenu.vue";
import WebRtc from "@/widgets/WebRtc.vue";
import { UserAgent, Registerer, Inviter, SessionState } from "sip.js";
import NotificationSoundMenu from "@/widgets/topbar/NotificationSoundMenu";

export default {
  components: {
    ProfileMenu,
    WebRtc,
    NotificationSoundMenu,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userAgent: null,
      inviter: null,
    };
  },
  methods: {
    async joinWebRtc() {
      const transportOptions = {
        server: "wss://v99.own.ag:8089/owg/ws",
        traceSip: true,
      };
      const uri = UserAgent.makeURI("sip:webrtc_client@v99.own.ag");

      const userAgentOptions = {
        authorizationPassword: "webrtc_client",
        authorizationUsername: "webrtc_client",
        transportOptions,
        uri,
      };
      this.userAgent = new UserAgent(userAgentOptions);
      const registerer = new Registerer(this.userAgent, {
        ice: true,
      });

      await this.userAgent.start();
      await registerer.register();
      this.makeCall();
    },

    async makeCall() {
      const target = UserAgent.makeURI("sip:10009@v88.own.ag");
      this.inviter = new Inviter(this.userAgent, target, {
        earlyMedia: true,
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: false,
          },
        },
      });

      this.inviter.stateChange.addListener((state) => {
        switch (state) {
          case SessionState.Initial:
            break;
          case SessionState.Establishing:
            break;
          case SessionState.Established:
            this.setupRemoteMedia(this.inviter);
            break;
          case SessionState.Terminating:
          // fall through
          case SessionState.Terminated:
            this.cleanupMedia();
            break;
          default:
            throw new Error("Unknown session state.");
        }
      });

      this.inviter.invite();
    },
    setupRemoteMedia(session) {
      const remoteStream = new MediaStream();
      session.sessionDescriptionHandler.peerConnection
        .getReceivers()
        .forEach((receiver) => {
          if (receiver.track) {
            remoteStream.addTrack(receiver.track);
          }
        });
      this.$refs.remoteAudio.srcObject = remoteStream;
      this.$refs.remoteAudio.play();
    },
    cleanupMedia() {
      this.$refs.remoteAudio.srcObject = null;
      this.$refs.remoteAudio.pause();
    },
  },
};
</script>

<style></style>
