import endpointsApi from "@/network/refactored/endpoints.js";

export const namespaced = true;

export const state = {
  endpoints: [],
  page: 1,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_ENDPOINTS(state, endpoints) {
    state.endpoints = endpoints;
  },

  ADD_ENDPOINTS(state, endpoint) {
    state.endpoints.unshift(endpoint);
  },

  REMOVE_ENDPOINT(state, id) {
    state.endpoints = state.endpoints.filter((end) => end.id !== id);
  },

  UPDATE_ENDPOINT(state, endpoint) {
    const i = state.endpoints.findIndex((end) => end.id === endpoint.id);
    if (state.endpoints[i]) {
      state.endpoints[i] = endpoint;
    }
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listEndpoints({ commit, state }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const { data } = await endpointsApi.listEndpoints(state.page);
      commit("SET_ENDPOINTS", data.data);
      commit("SET_TOTALS", data.total);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  addEndpoint({ commit }, { endpoint }) {
    commit("ADD_ENDPOINTS", endpoint);
  },

  updateEndpoint({ commit }, { endpoint }) {
    commit("UPDATE_ENDPOINT", endpoint);
  },

  removeEndpoint({ commit }, { id }) {
    commit("REMOVE_ENDPOINT", id);
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
};

export const getters = {
  endpoints: (state) => state.endpoints,
  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
