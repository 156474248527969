import callsApi from "@/network/refactored/active_calls.js";
import Pusher from "pusher-js";
import StorageUtil from "@/utils/LocalStorageUtil.js";

export const namespaced = true;

export const state = {
  calls: [],
  loading: false,
  error: null,

  dmtfNumber: null,
  pusher: null,
};

export const mutations = {
  SET_CALLS(state, calls) {
    state.calls = calls;
  },

  APPEND_CALL(state, call) {
    state.calls.unshift(call);
  },

  REMOVE_CALL_BRIDGE(state, channelId) {
    const call = state.calls.find((call) => call.channel_id === channelId);
    call.bridge_id = "";
  },

  UPDATE_CALL_STATUS(state, { channelId, status }) {
    const call = state.calls.find((call) => call.channel_id === channelId);
    if (call) {
      call.call_status = status;
    }
  },

  SET_MUTE_CALL(state, { callId, status }) {
    const call = state.calls.find((call) => call.id === callId);
    call.isMuted = status;
  },

  SET_RECORDING(state, { callId, status }) {
    const call = state.calls.find((call) => call.id === callId);
    call.recording_status = status ? "on" : "off";
  },

  SET_RECORDING_PAUSE(state, { callId, status }) {
    const call = state.calls.find((call) => call.id === callId);
    call.isRecordingPaused = status;
  },

  SET_RECORDING_MUTE(state, { callId, status }) {
    const call = state.calls.find((call) => call.id === callId);
    call.isRecordingMuted = status;
  },

  SET_DTMF_NUM(state, dtmf) {
    state.dmtfNumber = dtmf;
  },

  REMOVE_CALL(state, { channelId }) {
    const calls = state.calls.filter((call) => call.channel_id !== channelId);
    state.calls = calls;
  },

  SET_CALL_BRIDGE(state, { channelId, bridgeId }) {
    const call = state.calls.find((call) => call.channel_id === channelId);
    call.bridge_id = bridgeId;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listCalls({ commit }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const { data } = await callsApi.listCalls();
      if (data) {
        commit("SET_CALLS", data);
      }
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async removeBridge({ commit }, { channelId, bridgeId }) {
    commit("REMOVE_CALL_BRIDGE", channelId);
    await callsApi.removeChannel(bridgeId, channelId);
  },

  async changeBridge(
    { commit },
    { channelId, oldBridgeId, newBridgeId, answer = false }
  ) {
    commit("SET_CALL_BRIDGE", { channelId: channelId, bridgeId: newBridgeId });
    if (
      oldBridgeId &&
      oldBridgeId !== "" &&
      oldBridgeId !== "null" &&
      oldBridgeId !== "undefined"
    ) {
      await callsApi.removeChannel(oldBridgeId, channelId);
    }
    await callsApi.addChannel(newBridgeId, channelId, answer);
  },

  async muteCall({ commit }, { callId, status }) {
    if (status === true) {
      await callsApi.muteCall(callId);
    } else {
      await callsApi.unMuteCall(callId);
    }
    commit("SET_MUTE_CALL", { callId, status });
  },

  async recordCall({ commit }, { callId, status }) {
    if (status === true) {
      await callsApi.startRecording(callId);
    } else {
      await callsApi.stopRecording(callId);
    }
    commit("SET_RECORDING_PAUSE", { callId, status: false });
    commit("SET_RECORDING_MUTE", { callId, status: false });
    commit("SET_RECORDING", { callId, status });
  },

  async pauseRecording({ commit }, { callId, status }) {
    if (status === true) {
      await callsApi.pauseRecording(callId);
    } else {
      await callsApi.resumeRecording(callId);
    }
    commit("SET_RECORDING_PAUSE", { callId, status });
  },

  async muteRecording({ commit }, { callId, status }) {
    if (status === true) {
      await callsApi.muteRecording(callId);
    } else {
      await callsApi.unmuteRecording(callId);
    }
    commit("SET_RECORDING_MUTE", { callId, status });
  },

  async hangup({ commit }, { channelId, callId, reason = "normal" }) {
    await callsApi.hangupCall(callId, reason);
    commit("REMOVE_CALL", { channelId });
  },

  setDMTF({ commit }, { callId }) {
    commit("SET_DTMF_NUM", callId);
  },

  appendCall({ commit }, { call }) {
    commit("APPEND_CALL", call);
  },

  async sendDTMF({ state }, { dtmf }) {
    await callsApi.sendDTMF(state.dmtfNumber, dtmf);
  },

  initPusher({ state }) {
    state.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      auth: {
        headers: { Authorization: `Bearer ${StorageUtil.getUserAuthToken()}` },
      },
      authEndpoint:
        "https://o8rtlddhcc.execute-api.us-east-2.amazonaws.com/api/broadcasting/auth",
    });
  },

  pusherBind({ commit, dispatch, state }) {
    state.pusher.bind("call.status", (data) => {
      console.log(data.event);
      if (data.event?.call) {
        if (data.event?.status === "call.Hangup") {
          commit("REMOVE_CALL", {
            channelId: data.event?.call.channel_id,
          });
          dispatch("recentCalls/listCalls", true, { root: true });
        } else {
          const isInCall = state.calls.find(
            (call) => call.id === data.event.call.id
          );
          if (isInCall) {
            commit("UPDATE_CALL_STATUS", {
              channelId: data.event?.call.channel_id,
              //status: data.event?.call.call_status,
              status: data.event?.status,
            });
          } else {
            commit("APPEND_CALL", {
              ...data.event.call,
              call_status: data.event?.status,
            });
          }
        }
      }
    });

    state.pusher.bind("call.action.status", (data) => {
      console.log(data.event);
      if (data.event?.call) {
        switch (data.event.event_type) {
          case "incoming.call.hangup":
          case "incoming.call.forward":
            commit("REMOVE_CALL", {
              channelId: data.event?.call.channel_id,
            });
            dispatch("recentCalls/listCalls", true, { root: true });
            if (data.event.call.answer_at == null)
              dispatch("missedCalls/listMissedCalls", true, { root: true });
            dispatch(
              "incomingCalls/removeSession",
              { session: data.event.call },
              {
                root: true,
              }
            );
            break;
          case "incoming.call":
            if (state.calls.find((call) => call.id === data.event.call.id)) {
              commit("UPDATE_CALL_STATUS", {
                channelId: data.event?.call.channel_id,
                status: data.event?.status,
              });
            } else {
              commit("APPEND_CALL", {
                ...data.event.call,
                call_status: data.event?.status,
              });
              dispatch(
                "incomingCalls/addSession",
                {
                  session: {
                    ...data.event.call,
                    call_status: data.event?.status,
                  },
                },
                {
                  root: true,
                }
              );
            }
            break;
          case "call.record.start":
          case "call.record.stop":
            if (state.calls.find((call) => call.id === data.event.call.id)) {
              commit("SET_RECORDING", {
                callId: data.event?.call.id,
                status: data.event?.call.recording_status === "on",
              });
            }
            break;
          case "call.mute":
          case "call.unmute":
            if (state.calls.find((call) => call.id === data.event.call.id)) {
              commit("SET_MUTE_CALL", {
                callId: data.event?.call.id,
                status: data.event?.call.muted,
              });
            }
            break;
        }
      }
      // if (data.event?.call.call_status === "Down") {
      // commit("REMOVE_CALL", {
      //   channelId: data.event?.call.channel_id,
      // });
      //   dispatch("recentCalls/listCalls", true, { root: true });
      // } else {
      // const isInCall = state.calls.find(
      //   (call) => call.id === data.event.call.id
      // );
      // if (isInCall) {
      //   commit("UPDATE_CALL_STATUS", {
      //     channelId: data.event?.call.channel_id,
      //     status: data.event?.call.call_status,
      //   });
      // } else {
      //   commit("APPEND_CALL", data.event.call);
      // }
      // }
    });

    state.pusher.bind("bridge.action.status", () => {
      dispatch("roomsStore/listRooms", true, {
        root: true,
      });
    });
  },

  subscribeToChannel({ state }) {
    state.pusher.subscribe("v88-call-status");
    state.pusher.subscribe("v88-call-action-status");
    state.pusher.subscribe("v88-bridge-action-status");
  },
};

export const getters = {
  calls: (state) => state.calls,
  callsWithBridge: (state) => (bridgeId) => {
    return state.calls.filter((call) => call.bridge_id === bridgeId);
  },
  loading: (state) => state.loading,
  error: (state) => state.error,
  dmtfNumber: (state) => state.dmtfNumber,
};
