import callsApi from "@/network/calls.js";
import DateTimeUtil from "@/utils/DateTimeUtil.js";
export const namespaced = true;

export const state = {
  isLoadingActiveCalls: false,
  isLoadingRecentCalls: false,
  isLoadingMissedCalls: false,
  isLoadingActiveRooms: false,
  isLoadingRoomDetails: false,

  activeCalls: [],
  recentCalls: [],
  missedCalls: [],
  activeRooms: [],
  DIDs: [],

  isAddingRoom: false,

  selectedRoom: null,

  errorMessage: null,
};

export const mutations = {
  SET_ACTIVE_CALL_LOADING(state, isLoading) {
    state.isLoadingActiveCalls = isLoading;
  },
  SET_RECENT_CALL_LOADING(state, isLoading) {
    state.isLoadingRecentCalls = isLoading;
  },
  SET_MISSED_CALL_LOADING(state, isLoading) {
    state.isLoadingMissedCalls = isLoading;
  },
  SET_ACTIVE_ROOMS_LOADING(state, isLoading) {
    state.isLoadingActiveRooms = isLoading;
  },
  SET_ROOM_DETAILS_LOADING(state, isLoading) {
    state.isLoadingRoomDetails = isLoading;
  },

  SET_ACTIVE_CALLS(state, calls) {
    state.activeCalls = calls;
  },
  SET_RECENT_CALLS(state, calls) {
    state.recentCalls = calls;
  },
  SET_MISSED_CALLS(state, calls) {
    state.missedCalls = calls;
  },
  SET_ACTIVE_ROOMS(state, rooms) {
    state.activeRooms = rooms;
  },

  SET_ADDING_ROOM_LOADING(state, isLoading) {
    state.isAddingRoom = isLoading;
  },
  ADD_NEW_ROOM(state, room) {
    state.activeRooms.push(room);
  },
  SET_SELECTED_ROOM(state, roomId) {
    state.activeRooms.forEach((room) => {
      room.selected = false;
    });
    let room = state.activeRooms.filter((room) => room.bridgeId === roomId)[0];
    room.selected = true;
    state.selectedRoom = room;
  },
  SET_SELECTED_ROOM_CHANNELS(state, channels) {
    state.selectedRoom.channels = channels;
  },
  SET_UN_SELECTED_ROOM(state) {
    state.activeRooms.forEach((room) => {
      room.selected = false;
    });
    state.selectedRoom = null;
  },

  SET_START_CALL_RECORDING(state, callId) {
    let call = state.activeCalls.filter((call) => call.id === callId)[0];
    call.isRecording = true;
  },
  SET_STOP_CALL_RECORDING(state, callId) {
    let call = state.activeCalls.filter((call) => call.id === callId)[0];
    call.isRecording = false;
  },

  SET_MUTE_CALL(state, callId) {
    let call = state.activeCalls.filter((call) => call.id === callId)[0];
    call.isMuted = true;
  },
  SET_UNMUTE_CALL(state, callId) {
    let call = state.activeCalls.filter((call) => call.id === callId)[0];
    call.isMuted = false;
  },

  SET_ERROR_MESSAGE(state, error) {
    state.errorMessage = error;
  },

  ADD_ACTIVE_CALL(state, call) {
    state.activeCalls.push(call);
  },

  SET_DIDS(state, DIDs) {
    state.DIDs = DIDs;
  },
};

export const actions = {
  async listActiveCalls({ commit }) {
    commit("SET_ACTIVE_CALL_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.activeCalls();
      commit("SET_ACTIVE_CALLS", data);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_ACTIVE_CALL_LOADING", false);
    }
  },

  async listRecentCalls({ commit }) {
    commit("SET_RECENT_CALL_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.recentCalls();
      commit("SET_RECENT_CALLS", data.data);
      //commit("SET_ACTIVE_CALLS", data.data);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_RECENT_CALL_LOADING", false);
    }
  },

  async listMissedCalls({ commit }) {
    commit("SET_MISSED_CALL_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.missedCalls();
      commit("SET_MISSED_CALLS", data.data);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_MISSED_CALL_LOADING", false);
    }
  },

  async listActiveRooms({ commit }) {
    commit("SET_ACTIVE_ROOMS_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.activeRooms();
      commit("SET_ACTIVE_ROOMS", data);
      // commit("SET_ACTIVE_ROOMS", [
      //   {
      //     bridgeId: "br_id_60d48baa8df653.72088640",
      //     name: "ward1",
      //     channels: [],
      //     selected: false,
      //   },
      //   {
      //     bridgeId: "br_id_60d48beb374fa6.36719128",
      //     name: "ward2",
      //     channels: [],
      //     selected: false,
      //   },
      // ]);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_ACTIVE_ROOMS_LOADING", false);
    }
  },

  async createRoom({ commit }, { roomName, roomType }) {
    commit("SET_ADDING_ROOM_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.createRoom(roomName, roomType);
      commit("ADD_NEW_ROOM", {
        bridgeId: data.bridgeId,
        name: data.name,
        channels: data.channels,
        selected: false,
      });
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_ADDING_ROOM_LOADING", false);
    }
  },

  async addChannelToRoom({ commit, state }, { channelId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.addChannel(state.selectedRoom?.bridgeId, channelId);
      if (!state.selectedRoom.channels.includes(channelId)) {
        state.selectedRoom.channels.push(channelId);
      }
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },
  async removeChannelFromRoom({ commit, state }, { channelId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.removeChannel(state.selectedRoom?.bridgeId, channelId);
      if (state.selectedRoom.channels.includes(channelId)) {
        let index = state.selectedRoom.channels.indexOf(channelId);
        if (index > -1) {
          state.selectedRoom.channels.splice(index, 1);
        }
      }
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async loadSelectedRoomData({ commit }, roomId) {
    commit("SET_ROOM_DETAILS_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.loadRoomDetails(roomId);
      commit("SET_SELECTED_ROOM_CHANNELS", data.channels);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_ROOM_DETAILS_LOADING", false);
    }
  },

  selectRoom({ commit }, roomId) {
    commit("SET_SELECTED_ROOM", roomId);
  },

  unSelectRoom({ commit }) {
    commit("SET_UN_SELECTED_ROOM");
  },

  async startCallRecording({ commit }, { callId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.startRecording(callId);
      commit("SET_START_CALL_RECORDING", callId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },
  async stopCallRecording({ commit }, { callId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.stopRecording(callId);
      commit("SET_STOP_CALL_RECORDING", callId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async muteCall({ commit }, { callId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.muteCall(callId);
      commit("SET_MUTE_CALL", callId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },
  async unMuteCall({ commit }, { callId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.unMuteCall(callId);
      commit("SET_UNMUTE_CALL", callId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async hangupCall({ commit, state }, { callId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.hangupCall(callId);
      let activeCalls = state.activeCalls.filter((call) => call.id !== callId);
      state.activeCalls = activeCalls;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async sendDTMF({ commit }, { callId, dtmf }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.sendDTMF(callId, dtmf);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async generateCalledNumber({ commit }, { number }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      let { data } = await callsApi.generateCalledNumber(number);
      return data;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async placeCall(
    { commit, state },
    { number, callerIdNumber, callerIdName, bridgeId }
  ) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      let { data } = await callsApi.placeCall(
        number,
        callerIdNumber,
        callerIdName,
        bridgeId
      );
      commit("ADD_ACTIVE_CALL", data);
      if (bridgeId !== "") {
        if (!state.selectedRoom.channels.includes(data.channel_id)) {
          state.selectedRoom.channels.push(data.channel_id);
        }
      }
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async listDIDs({ commit }, { outreach }) {
    try {
      const { data } = await callsApi.listDIDs(outreach);
      commit("SET_DIDS", data);
    } catch (error) {
      commit("SET_DIDS", []);
    }
  },
};

export const getters = {
  isLoadingActiveCalls: (state) => state.isLoadingActiveCalls,
  isLoadingRecentCalls: (state) => state.isLoadingRecentCalls,
  isLoadingMissedCalls: (state) => state.isLoadingMissedCalls,
  isLoadingActiveRooms: (state) => state.isLoadingActiveRooms,
  isLoadingRoomDetails: (state) => state.isLoadingRoomDetails,
  isAddingRoom: (state) => state.isAddingRoom,

  errorMessage: (state) => state.errorMessage,

  activeCalls: (state) =>
    state.activeCalls.map((call) => {
      return {
        id: call.id,
        callerName: call.caller_id_name,
        callerNumber: call.caller_id_num,
        number: call.number,
        duration: DateTimeUtil.secondsToMinutesAndSeconds(call.duration),
      };
    }),
  recentCalls: (state) =>
    state.recentCalls.map((call) => {
      return {
        id: call.id,
        callerName: call.caller_id_name,
        callerNumber: call.caller_id_num,
        number: call.number,
        hangup_reason: call.hangup_reason,
        room: call.bridge_id,
        callDate: DateTimeUtil.timestampToDate(call.answer_at ?? call.end_at),
        callTime: DateTimeUtil.timestampToTime(call.answer_at ?? call.end_at),
      };
    }),
  missedCalls: (state) =>
    state.missedCalls.map((call) => {
      return {
        id: call.id,
        callerName: call.caller_id_name,
        callerNumber: call.caller_id_num,
        number: call.number,
        room: call.bridge_id,
        callDate: DateTimeUtil.timestampToDate(call.answer_at ?? call.end_at),
        callTime: DateTimeUtil.timestampToTime(call.answer_at ?? call.end_at),
      };
    }),
  activeRooms: (state) => state.activeRooms,
  selectedRoom: (state) => state.selectedRoom,
  activeCallsNotInRoom: (state) =>
    state.activeCalls
      .filter((call) => !state.selectedRoom?.channels.includes(call.channel_id))
      .map((call) => {
        return {
          id: call.id,
          callerName: call.caller_id_name,
          callerNumber: call.caller_id_num,
          number: call.number,
          duration: DateTimeUtil.secondsToMinutesAndSeconds(call.duration),
          channelId: call.channel_id,
        };
      }),

  isRecentCallsEmpty: (state) => state.recentCalls?.length == 0,
  isMissedCallsEmpty: (state) => state.missedCalls?.length == 0,
  isActiveCallsEmpty: (state) => state.activeCalls?.length == 0,
  isActiveRoomsEmpty: (state) => state.activeRooms?.length == 0,

  selectedRoomChannels: (state) =>
    state.activeCalls.filter((call) =>
      state.selectedRoom?.channels.includes(call.channel_id)
    ),

  DIDs: (state) => state.DIDs,
};
