import callsApi from "@/network/calls.js";

export const namespaced = true;

export const state = {
  numbers: "",
  generated: [],
  errorMessage: null,
  recall: null,
};

export const mutations = {
  SET_ERROR_MESSAGE(state, error) {
    state.errorMessage = error;
  },
};

export const actions = {
  async generate({ commit }, { number }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      let { data } = await callsApi.generateCalledNumber(number);
      return data;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async getSession({ commit }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      let data = await callsApi.getActiveSession();
      return data;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async createSession({ commit }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      let data = await callsApi.createSession();
      return data;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async placeCall(
    { commit },
    {
      number,
      callerIdNumber,
      callerIdName,
      bridgeId,
      local = false,
      silentJoin = false,
      record = false,
    }
  ) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      let { data } = await callsApi.placeCall(
        number,
        callerIdNumber,
        callerIdName,
        bridgeId,
        local,
        silentJoin,
        record
      );
      return data;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },
};

export const getters = {};
