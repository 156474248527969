import axiosInstance, { QUEUE_CALLS_URL, QUEUES_URL } from "../config.js";

export default {
  listQueues(page) {
    return axiosInstance.get(`${QUEUES_URL}?page=${page}`);
  },

  createQueue(name) {
    return axiosInstance.post(`${QUEUES_URL}`, {
      name,
    });
  },

  update(id, name) {
    return axiosInstance.patch(`${QUEUES_URL}/${id}`, {
      name,
    });
  },

  delete(id) {
    return axiosInstance.delete(`${QUEUES_URL}/${id}`);
  },

  getQueueDetails(id) {
    return axiosInstance.get(`${QUEUES_URL}/${id}`);
  },

  createQueueCall(queue_id, number, caller_id_num, caller_id_name, notes) {
    return axiosInstance.post(`${QUEUE_CALLS_URL}`, {
      queue_id: queue_id,
      number: number,
      caller_id_num: caller_id_num,
      caller_id_name: caller_id_name,
      notes: notes,
    });
  },

  updateQueueCall(id, number, caller_id_num, caller_id_name, notes) {
    return axiosInstance.patch(`${QUEUE_CALLS_URL}/${id}`, {
      number: number,
      caller_id_num: caller_id_num,
      caller_id_name: caller_id_name,
      notes: notes,
    });
  },

  updateQueueCallStatus(id, status) {
    return axiosInstance.post(`${QUEUE_CALLS_URL}/${id}/update-status`, {
      status: status,
    });
  },

  deleteQueueCall(id) {
    return axiosInstance.delete(`${QUEUE_CALLS_URL}/${id}`);
  },
};
