import axiosInstance, { LOGIN_URL, PROFILE_URL } from "./config.js";

export default {
  login(email, password) {
    return axiosInstance.post(LOGIN_URL, {
      username: email,
      password: password,
    });
  },

  loadProfile() {
    return axiosInstance.get(PROFILE_URL);
  },

  loginAs(userId) {
    return axiosInstance.post(`${LOGIN_URL}/${userId}`, {});
  },
};
