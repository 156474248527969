import axiosInstance, {
  ACTIVE_CALLS_URL,
  REMOVE_CHANNEL_URL,
  ADD_CHANNEL_URL,
  MUTE_CALL_URL,
  UNMUTE_CALL_URL,
  HANGUP_CALL_URL,
  SEND_CALL_DTMF_URL,
  START_CALL_ROCERDING_URL,
  STOP_CALL_ROCERDING_URL,
  PAUSE_RECORDING_URL,
  RESUME_RECORDING_URL,
  MUTE_RECORDING_URL,
  UNMUTE_RECORDING_URL,
} from "../config.js";

export default {
  listCalls() {
    return axiosInstance.get(`${ACTIVE_CALLS_URL}`);
  },

  removeChannel(roomId, channelId) {
    return axiosInstance.post(
      `${REMOVE_CHANNEL_URL.replace("{roomId}", roomId)}`,
      {
        channelId: channelId,
      }
    );
  },

  addChannel(roomId, channelId, answer = false) {
    return axiosInstance.post(
      `${ADD_CHANNEL_URL.replace("{roomId}", roomId)}`,
      {
        channelId: channelId,
        answer: answer,
      }
    );
  },

  muteCall(callId) {
    return axiosInstance.post(`${MUTE_CALL_URL.replace("{callId}", callId)}`);
  },

  unMuteCall(callId) {
    return axiosInstance.post(`${UNMUTE_CALL_URL.replace("{callId}", callId)}`);
  },

  hangupCall(callId, reason) {
    return axiosInstance.post(
      `${HANGUP_CALL_URL.replace("{callId}", callId)}`,
      {
        reason: reason,
      }
    );
  },

  sendDTMF(callId, dtmf) {
    return axiosInstance.post(
      `${SEND_CALL_DTMF_URL.replace("{callId}", callId)}`,
      {
        dtmf: dtmf,
      }
    );
  },

  // --> Recordings
  startRecording(callId) {
    return axiosInstance.post(
      `${START_CALL_ROCERDING_URL.replace("{callId}", callId)}`
    );
  },
  stopRecording(callId) {
    return axiosInstance.post(
      `${STOP_CALL_ROCERDING_URL.replace("{callId}", callId)}`
    );
  },
  pauseRecording(callId) {
    return axiosInstance.post(
      `${PAUSE_RECORDING_URL.replace("{callId}", callId)}`
    );
  },
  resumeRecording(callId) {
    return axiosInstance.post(
      `${RESUME_RECORDING_URL.replace("{callId}", callId)}`
    );
  },
  muteRecording(callId) {
    return axiosInstance.post(
      `${MUTE_RECORDING_URL.replace("{callId}", callId)}`
    );
  },
  unmuteRecording(callId) {
    return axiosInstance.post(
      `${UNMUTE_RECORDING_URL.replace("{callId}", callId)}`
    );
  },
  // -- END Recordings
};
