import callNotesApi from "@/network/refactored/call_notes.js";

export const namespaced = true;

export const state = {
  call: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_CALL(state, call) {
    state.call = call;
  },

  SET_CALL_NOTE(state, note) {
    state.call.notes = note;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  setCall({ commit }, { call }) {
    commit("SET_CALL", call);
  },

  async saveNote({ commit, state }, { note }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      await callNotesApi.updateNote(state.call.id, note);
      commit("SET_CALL_NOTE", note);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export const getters = {
  call: (state) => state.call,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
