import axios from "axios";
import StorageUtil from "@/utils/LocalStorageUtil.js";

export default axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL, //"https://o8rtlddhcc.execute-api.us-east-2.amazonaws.com/api",
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(StorageUtil.getUserAuthToken() && {
      Authorization: `Bearer ${StorageUtil.getUserAuthToken()}`,
    }),
  },
});

export const LOGIN_URL = "/login";
export const PROFILE_URL = "/profile";
export const USERS_URL = "/user";
export const ACTIVE_CALLS_URL = "/active-calls";
export const INCOMING_CALLS_URL = "/incoming-calls";
export const RECENT_CALLS_URL = "/call";
export const MISSED_CALLS_URL = "/missed-calls";
export const ACTIVE_ROOMS_URL = "/bridge";
export const DELETE_ROOMS_URL = "/bridge/{roomId}";
export const LIST_DIDS_URL = "/list-dids";
export const ADD_CHANNEL_URL = "/bridge/{roomId}/add-channel";
export const REMOVE_CHANNEL_URL = "/bridge/{roomId}/remove-channel";

export const START_CALL_ROCERDING_URL = "/call/{callId}/record-start";
export const STOP_CALL_ROCERDING_URL = "/call/{callId}/record-stop";

export const PAUSE_RECORDING_URL = "/call/{callId}/record-pause";
export const RESUME_RECORDING_URL = "/call/{callId}/record-resume";

export const MUTE_RECORDING_URL = "/call/{callId}/record-mute";
export const UNMUTE_RECORDING_URL = "/call/{callId}/record-unmute";

export const MUTE_CALL_URL = "/call/{callId}/mute";
export const UNMUTE_CALL_URL = "/call/{callId}/unmute";

export const HANGUP_CALL_URL = "/call/{callId}/hangup";

export const SEND_CALL_DTMF_URL = "/call/{callId}/dtmf";

export const GENERATE_CALLER_NUMBER_URL = "/callerid-gen?number=";

export const MAKE_CALL_URL = "/call";

export const RECORDING_URL = "/recording";

export const WEBRTC_USERS = "/active-webrtc-connection";

export const UPDATE_CALL_NOTES_URL = "/call/{callId}";

export const LIST_SESSIONS_URL = "/session";
export const GET_SESSION_URL = "/active-session";
export const CREATE_SESSION_URL = "/session";

export const INBOUNDS_URL = "/inbound";
export const RESTORE_INBOUND_URL = "/inbound/restore";

export const QUEUES_URL = "/queue";

export const QUEUE_CALLS_URL = "/queue-calls";

export const VOICE_MAIL_URL = "/voicemailMessage";

export const ENDPOINTS_URL = "/endpoint";
export const ENDPOINTS_GREETING_URL = "/voicemailGreeting";

export const BRIDGE_CALL_URL = "/birdge-call";

export const LIST_CONTACTS_URL = "/contacts";
export const SEND_SMS_URL = "/message";
export const UPLOAD_MMS_FILE = "/mms-upload";
export const MARK_SMS_AS_SEEN_URL = "/mark-as-seen";
export const DELETE_SMS_URL = "/delete-message";
export const RESTORE_SMS_URL = "/restore-message";
export const OUTREACH_FLAG = 1;
