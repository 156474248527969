import callsApi from "@/network/refactored/recent_calls.js";

export const namespaced = true;

export const state = {
  calls: [],
  search: "",
  page: 1,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_CALLS(state, calls) {
    state.calls = calls;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_SEARCH(state, term) {
    state.search = term;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listCalls({ commit, state }, reload = false) {
    if (reload && state.page !== 1) return;

    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const { data } = await callsApi.listCalls(state.page, state.search);
      commit("SET_CALLS", data.data);
      commit("SET_TOTALS", data.total);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },

  setSearchTerm({ commit }, { term }) {
    commit("SET_PAGE", 1);
    commit("SET_SEARCH", term);
  },
};

export const getters = {
  calls: (state) => state.calls,
  search: (state) => state.search,
  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
