import recordingApi from "@/network/refactored/recordings.js";
import Pusher from "pusher-js";
import StorageUtil from "@/utils/LocalStorageUtil";

export const namespaced = true;

export const state = {
  sessions: [],
  search: "",
  number: "",
  from: "",
  to: "",
  recordingOnly: 1,
  page: 1,
  totals: 0,
  session: null,
  snackbarProps: null,

  loading: false,
  error: null,
  pusher: null,
};

export const mutations = {
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_RECORDING_ONLY(state, value) {
    state.recordingOnly = value;
  },

  SET_SEARCH(state, { term, number }) {
    state.search = term;
    state.number = number;
  },

  SET_DATE_RANGE(state, { from, to }) {
    state.from = from;
    state.to = to;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_SESSION(state, session) {
    state.session = session;
  },

  SNACKBAR_PROPS(state, props) {
    state.snackbarProps = props;
  },
};

export const actions = {
  async listSessions(
    { commit, state },
    { recordingOnly } = { recordingOnly: state.recordingOnly }
  ) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const { data } = await recordingApi.listCalls(
        state.page,
        state.search,
        state.number,
        recordingOnly,
        state.from,
        state.to
      );
      commit("SET_SESSIONS", data.data);
      commit("SET_TOTALS", data.total);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },

  setSession({ commit }, session) {
    commit("SET_SESSION", session);
  },

  setFilterValues({ commit }, { term, number, from, to, recordingOnly }) {
    commit("SET_PAGE", 1);
    commit("SET_SEARCH", { term, number });
    commit("SET_DATE_RANGE", { from, to });
    commit("SET_RECORDING_ONLY", recordingOnly);
  },

  initRecordingPusher({ state }) {
    state.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      auth: {
        headers: { Authorization: `Bearer ${StorageUtil.getUserAuthToken()}` },
      },
      authEndpoint:
        "https://o8rtlddhcc.execute-api.us-east-2.amazonaws.com/api/broadcasting/auth",
    });
  },

  pusherRecordingBind({ commit, state, dispatch }) {
    state.pusher.bind("generate.zip.status", (data) => {
      const stateSession = state.session;
      const { session } = data.event;
      if (stateSession?.id === session.id) {
        stateSession.archiving = data.event.session.archiving;
        stateSession.download_zip = data.event.session.download_zip;
        commit("SET_SESSION", stateSession);
      }
      if (data.event.percent === 100) {
        dispatch(
          "snackbarStore/sessionDownloadReady",
          { session },
          { root: true }
        );
      }
    });
  },

  subscribeToRecordingChannel({ state }) {
    state.pusher.subscribe("v88-generate-status");
  },
};

export const getters = {
  sessions: (state) => state.sessions,
  search: (state) => state.search,
  page: (state) => state.page,
  totals: (state) => state.totals,
  session: (state) => state.session,
  snackbarProps: (state) => state.snackbarProps,

  loading: (state) => state.loading,
  error: (state) => state.error,
};
