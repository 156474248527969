import axiosInstance, { LIST_SESSIONS_URL, MAKE_CALL_URL } from "../config.js";

export default {
  listCalls(page, search, number, recordingOnly, from, to) {
    let params = "";
    if (search) {
      params += `&s=${search}`;
    }
    if (number) {
      params += `&filters[number]=${number}`;
    }
    params += `&filters[with_recordings_only]=${recordingOnly}`;

    return axiosInstance.get(
      `${LIST_SESSIONS_URL}?page=${page}&filters[date_from]=${from}&filters[date_to]=${to}${params}`
    );
  },

  loadSession(id) {
    return axiosInstance.get(`${LIST_SESSIONS_URL}/${id}`);
  },

  loadCall(id) {
    return axiosInstance.get(`${MAKE_CALL_URL}/${id}`);
  },

  downloadSessionCalls(id) {
    return axiosInstance.get(`${LIST_SESSIONS_URL}/${id}/download`);
  },
};
