import { createStore } from "vuex";

import * as auth from "@/store/modules/auth.js";
import * as users from "@/store/modules/users.js";
import * as calls from "@/store/modules/calls.js";
import * as smsStore from "@/store/modules/sms.js";
import * as snackbarStore from "@/store/modules/snackbarStore.js";

import * as rooms from "@/store/modules/calls/rooms.js";
import * as channels from "@/store/modules/calls/channels.js";
import * as place from "@/store/modules/calls/place.js";
import * as recording from "@/store/modules/calls/recording.js";

import * as recentCalls from "@/modules/recent_calls/recent_call_store.js";
import * as missedCalls from "@/modules/missed_calls/missed_call_store.js";
import * as activeCalls from "@/modules/active_calls/active_call_store.js";
import * as callNotes from "@/modules/call_notes/call_notes_store.js";
import * as roomsStore from "@/modules/rooms/rooms_store.js";
import * as RecordingsStore from "@/modules/recordings/recordings_store.js";
import * as incomingCalls from "@/modules/incoming_calls/incoming_calls_store.js";
import * as inbounds from "@/modules/inbounds/inbounds_store.js";
import * as queues from "@/modules/queues/queues_store.js";
import * as voicemail from "@/modules/voicemail/voicemail_store.js";
import * as endpoints from "@/modules/end_points/end_points.js";

export default createStore({
  state: {
    route: null,
  },
  mutations: {
    SET_ROUTE(state, route) {
      state.route = route;
    },
  },
  modules: {
    auth,
    users,
    calls,
    rooms,
    channels,
    place,
    recording,
    smsStore,
    snackbarStore,

    roomsStore,
    recentCalls,
    missedCalls,
    activeCalls,
    callNotes,
    //webRtc,
    RecordingsStore,
    incomingCalls,
    inbounds,
    queues,
    voicemail,
    endpoints,
  },
});
