import callsApi from "@/network/calls.js";

export const namespaced = true;

export const state = {
  rooms: [],
  loading: false,
  error: null,
};

export const mutations = {
  SET_ROOMS(state, rooms) {
    state.rooms = rooms;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listRooms({ commit }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      let data = null;
      // let { data } = await callsApi.activeRooms();

      // if (data.length < 2) {
      const rooms = [];

      const room1 = await callsApi.loadRoomDetails(
        "br_id_6107f36fb2d317.14913090"
      );
      if (room1.data === 404) {
        const room1 = await callsApi.createRoom(
          "br_id_6107f36fb2d317.14913090",
          "Main Conference",
          "mixing,dtmf_events"
        );
        rooms.push(room1.data);
      } else {
        rooms.push(room1.data);
      }

      const room2 = await callsApi.loadRoomDetails(
        "br_id_6107f36fb2d317.14913095"
      );
      if (room2.data === 404) {
        const room2 = await callsApi.createRoom(
          "br_id_6107f36fb2d317.14913095",
          "Main Conference2",
          "mixing,dtmf_events"
        );
        rooms.push(room2.data);
      } else {
        rooms.push(room2.data);
      }

      const room3 = await callsApi.loadRoomDetails(
        "br_id_6107f359416ac8.36864355"
      );
      if (room3.data === 404) {
        const room3 = await callsApi.createRoom(
          "br_id_6107f359416ac8.36864355",
          "Holding",
          "holding,dtmf_events"
        );
        rooms.push(room3.data);
      } else {
        rooms.push(room3.data);
      }
      data = rooms;
      // }

      commit("SET_ROOMS", data);
      // commit("SET_ROOMS", [
      //   {
      //     bridgeId: "br_id_6107f36fb2d317.14913090",
      //     technology: "simple_bridge",
      //     bridge_type: "mixing",
      //     name: "Main Conference",
      //     created_at: 1623077897,
      //     isRecording: false,
      //     expanded: false,
      //   },
      //   {
      //     bridgeId: "br_id_6107f359416ac8.36864355",
      //     technology: "simple_bridge",
      //     bridge_type: "holding",
      //     name: "Holding",
      //     created_at: 1623077950,
      //     isRecording: false,
      //     expanded: false,
      //   },
      // ]);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  toggleExpand({ state }, { bridgeId, expanded }) {
    const room = state.rooms.find((room) => room.bridgeId === bridgeId);
    if (room) {
      room.expanded = expanded;
    }
  },
};

export const getters = {
  rooms: (state) =>
    state.rooms.map((room) => {
      if (typeof room.expanded === "undefined") {
        room.expanded = room.channels.length > 0;
      }
      room.name = room.name ? room.name : "(No Name)";
      return room;
    }),
  isBridgeValid: (state) => (bridgeId) => {
    return state.rooms.find((room) => room.bridgeId === bridgeId)
      ? true
      : false;
  },
  loading: (state) => state.loading,
  error: (state) => state.error,
};
