export const namespaced = true;

export const state = {
  snackbarProps: null,
};

export const mutations = {
  SNACKBAR_PROPS(state, props) {
    state.snackbarProps = props;
  },
};

export const actions = {
  sessionDownloadReady({ commit }, { session }) {
    commit("SNACKBAR_PROPS", {
      type: "success",
      position: "bottom",
      text: `Session (${session.name} #${session.id}) is ready to be downloaded`,
      close: true,
      time: 5000,
      button: "Go To Session",
      action: () => {
        window.open(`/session/${session.id}`);
      },
    });
  },
  SMSReceived({ commit }) {
    commit("SNACKBAR_PROPS", {
      type: "success",
      position: "bottom",
      text: `SMS Received`,
      close: true,
      time: 5000,
      button: "Go To SMS",
      action: () => {
        window.open("/sms");
      },
    });
  },
  showMessage({ commit }, { message }) {
    commit("SNACKBAR_PROPS", {
      type: "default",
      position: "bottom",
      text: message,
      close: true,
      time: 1000,
    });
  },
  errorMessage({ commit }, { errorMessage }) {
    commit("SNACKBAR_PROPS", {
      type: "danger",
      position: "bottom",
      text: errorMessage,
      close: true,
      time: 5000,
    });
  },
};

export const getters = {
  snackbarProps: (state) => state.snackbarProps,
};
