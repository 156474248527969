import smsApi from "@/network/refactored/sms";
import Pusher from "pusher-js";
import StorageUtil from "@/utils/LocalStorageUtil";
export const namespaced = true;

export const state = {
  contacts: [],
  outreach_contacts: [],
  incomingSMSMuted: false,
  screenshotMode: false,
  outreachMode: false,
};

export const mutations = {
  SET_CONTACTS(state, contacts) {
    state.contacts = contacts;
  },
  SET_SMS_MUTE(state, value) {
    state.incomingSMSMuted = value;
  },
  SET_SCREENSHOT_MODE(state, screenshotMode) {
    state.screenshotMode = screenshotMode;
  },
  SET_OUTREACH_MODE(state, outreachMode) {
    state.outreachMode = outreachMode;
  },
};

export const actions = {
  async listContacts({ commit, dispatch }, { phone_number, outreach }) {
    try {
      const { data } = await smsApi.listContacts(phone_number, outreach);
      commit("SET_CONTACTS", data.contacts ?? []);
    } catch (error) {
      dispatch(
        "snackbarStore/errorMessage",
        {
          errorMessage:
            error?.response?.data?.message ?? "Something went wrong",
        },
        { root: true }
      );
    }
  },

  async sendSMS({ dispatch }, { didNumber, number, textMessage, file }) {
    try {
      let media_urls = [];
      if (file) {
        let formData = new FormData();
        formData.append("mms_file", file);
        const { data } = await smsApi.uploadMMSFile(formData);
        if (data.mms_file_url) {
          media_urls.push(data.mms_file_url);
        }
      }
      await smsApi.sendSMS(didNumber, number, textMessage, media_urls);
    } catch (error) {
      let errorData = error?.response?.data;
      let errorMessage =
        errorData?.error ?? errorData?.message ?? "Something went wrong";
      dispatch(
        "snackbarStore/errorMessage",
        {
          errorMessage: errorMessage,
        },
        { root: true }
      );
    }
  },

  async deleteMessage({ dispatch }, { id, outreach }) {
    try {
      await smsApi.deleteMessage(id);
      dispatch(
        "smsStore/listContacts",
        { phone_number: null, outreach: outreach },
        { root: true }
      );
    } catch (error) {
      dispatch(
        "snackbarStore/errorMessage",
        {
          errorMessage:
            error?.response?.data?.message ?? "Something went wrong",
        },
        { root: true }
      );
    }
  },

  async restoreMessage({ dispatch }, { id, outreach }) {
    try {
      await smsApi.restoreMessage(id);
      dispatch(
        "smsStore/listContacts",
        { phone_number: null, outreach: outreach },
        { root: true }
      );
    } catch (error) {
      dispatch(
        "snackbarStore/errorMessage",
        {
          errorMessage:
            error?.response?.data?.message ?? "Something went wrong",
        },
        { root: true }
      );
    }
  },

  async markAsSeen({ dispatch }, { didNumber, number }) {
    try {
      await smsApi.markAsSeen(didNumber, number);
    } catch (error) {
      dispatch(
        "snackbarStore/errorMessage",
        {
          errorMessage:
            error?.response?.data?.message ?? "Something went wrong",
        },
        { root: true }
      );
    }
  },

  initSMSPusher({ state }) {
    state.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      auth: {
        headers: { Authorization: `Bearer ${StorageUtil.getUserAuthToken()}` },
      },
      authEndpoint:
        "https://o8rtlddhcc.execute-api.us-east-2.amazonaws.com/api/broadcasting/auth",
    });
  },

  pusherSMSBind({ state, dispatch, rootState }) {
    state.pusher.bind("v88.sms", (data) => {
      const user = StorageUtil.getUserData();
      const eventMessage = data.event.message;
      if (rootState.route === "SMS")
        dispatch(
          "smsStore/listContacts",
          { phone_number: null, outreach: 0 },
          { root: true }
        );

      if (rootState.route === "Outreach")
        dispatch(
          "smsStore/listContacts",
          { phone_number: null, outreach: 1 },
          { root: true }
        );
      else if (user.id !== eventMessage.user_id) {
        if (!state.incomingSMSMuted) {
          if (!window.ion) {
            import("ion-sound").then(() => {
              window.ion.sound({
                sounds: [
                  {
                    name: "tune",
                  },
                  {
                    name: "sms",
                  },
                ],
                volume: 1,
                path: "https://v88-assets.s3.us-east-2.amazonaws.com/",
                preload: true,
              });
              window.ion.sound.play("sms");
            });
          } else {
            window.ion.sound.play("sms");
          }
        }
        dispatch("snackbarStore/SMSReceived", true, { root: true });
      }
    });
  },

  subscribeToSMSChannel({ state }) {
    state.pusher.subscribe("v88-notification-sms");
  },

  setSMSMute({ commit }, { value }) {
    commit("SET_SMS_MUTE", value);
  },

  setScreenshotMode({ commit }, value) {
    commit("SET_SCREENSHOT_MODE", value);
  },
  setOutreachMode({ commit }, value) {
    commit("SET_OUTREACH_MODE", value);
  },
};

export const getters = {
  contacts: (state) => state.contacts,
  outreach_contacts: (state) => state.outreach_contacts,
  incomingSMSMuted: (state) => state.incomingSMSMuted,
  screenshotMode: (state) => state.screenshotMode,
  outreachMode: (state) => state.outreachMode,
};
