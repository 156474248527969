import queuesApi from "@/network/refactored/queues.js";

export const namespaced = true;

export const state = {
  queues: [],
  queue: null,
  page: 1,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_QUEUES(state, queues) {
    state.queues = queues;
  },

  ADD_QUEUES(state, queue) {
    state.queues.unshift(queue);
  },

  UPDATE_QUEUES(state, queue) {
    const i = state.queues.findIndex((que) => que.id === queue.id);
    if (state.queues[i]) {
      state.queues[i] = queue;
    }
  },

  REMOVE_QUEUES(state, id) {
    state.queues = state.queues.filter((queue) => queue.id !== id);
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },

  SET_QUEUE(state, queue) {
    state.queue = queue;
  },

  ADD_QUEUE_CALL(state, queueCall) {
    state.queue.calls.unshift(queueCall);
  },

  EDIT_QUEUE_CALL(state, queueCall) {
    const i = state.queue.calls.findIndex((que) => que.id === queueCall.id);
    if (state.queue.calls[i]) {
      state.queue.calls[i] = queueCall;
    }
  },

  UPDATE_QUEUE_CALL_STATUS(state, { callID, status }) {
    const i = state.queue.calls.findIndex((que) => {
      return que.id === callID;
    });
    if (state.queue.calls[i]) {
      state.queue.calls[i].status = status;
    }
  },

  REMOVE_QUEUE_CALL(state, id) {
    state.queue.calls = state.queue.calls.filter((call) => call.id !== id);
  },
};

export const actions = {
  async listQueues({ commit, state }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const { data } = await queuesApi.listQueues(state.page);
      commit("SET_QUEUES", data.data);
      commit("SET_TOTALS", data.total);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  addQueue({ commit }, { queue }) {
    commit("ADD_QUEUES", queue);
  },

  updateQueue({ commit }, { queue }) {
    commit("UPDATE_QUEUES", queue);
  },

  removeQueue({ commit }, { queueId }) {
    commit("REMOVE_QUEUES", queueId);
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },

  addQueueCall({ commit }, { queueCall }) {
    commit("ADD_QUEUE_CALL", queueCall);
  },

  editQueueCall({ commit }, { queueCall }) {
    commit("EDIT_QUEUE_CALL", queueCall);
  },

  updateQueueCallStatus({ commit }, { callID, status }) {
    commit("UPDATE_QUEUE_CALL_STATUS", {
      callID: Number(callID),
      status: status,
    });
  },

  removeQueueCall({ commit }, { queueCallId }) {
    commit("REMOVE_QUEUE_CALL", queueCallId);
  },

  async getQueueDetails({ commit }, queueID) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const { data } = await queuesApi.getQueueDetails(queueID);
      commit("SET_QUEUE", data);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export const getters = {
  queues: (state) => state.queues,
  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading ?? true,
  error: (state) => state.error,
  queue: (state) => state.queue ?? null,
};
