import axiosInstance, {
  ENDPOINTS_GREETING_URL,
  ENDPOINTS_URL,
} from "../config.js";
import { post } from "axios";
import StorageUtil from "@/utils/LocalStorageUtil.js";

export default {
  listEndpoints(page) {
    return axiosInstance.get(`${ENDPOINTS_URL}?page=${page}`);
  },

  getEndpoint(id) {
    return axiosInstance.get(`${ENDPOINTS_URL}/${id}`);
  },

  createEndPoint(username, password, callerid, voicemail_pin, webrtc, notes) {
    return axiosInstance.post(`${ENDPOINTS_URL}`, {
      username,
      password,
      callerid,
      voicemail_pin,
      webrtc,
      notes,
    });
  },

  update(id, username, password, callerid, voicemail_pin, webrtc, notes) {
    return axiosInstance.patch(`${ENDPOINTS_URL}/${id}`, {
      username,
      password,
      callerid,
      voicemail_pin,
      webrtc,
      notes,
    });
  },

  delete(username) {
    return axiosInstance.delete(`${ENDPOINTS_URL}/${username}`);
  },

  uploadGreeting(formData) {
    const url = `${process.env.VUE_APP_BACKEND_URL}/upload-vm-greeting`;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${StorageUtil.getUserAuthToken()}`,
      },
    };
    return post(url, formData, config);
  },

  deleteGreeting(id) {
    return axiosInstance.delete(`${ENDPOINTS_GREETING_URL}/${id}`);
  },
};
