<template>
  <div class="min-h-screen overflow-hidden bg-gray-100 flex flex-col">
    <!-- Top nav-->
    <header class="flex-shrink-0 relative h-16 bg-white flex items-center">
      <!-- Logo area -->
      <div class="inset-y-0 left-0 static flex-shrink-0">
        <a
          href="/"
          class="
            flex
            items-center
            justify-center
            h-16
            bg-gray-800
            focus:outline-none
            focus:ring-2
            focus:ring-inset
            focus:ring-indigo-600
            w-20
          "
        >
          <img class="h-8 w-auto" src="../assets/logo.png" alt="Workflow" />
        </a>
      </div>
      <TopBar :title="title" />
    </header>

    <!-- Bottom section -->
    <div class="min-h-0 flex-1 flex overflow-hidden">
      <!-- Narrow sidebar-->
      <SideBar />
      <!-- Main area -->
      <main
        :class="[
          title === 'SMS' || title === 'Outreach' ? 'pb-4' : 'pb-16',
          'min-w-0 flex-1 border-t border-gray-200 lg:flex pt-4',
        ]"
      >
        <section
          aria-labelledby="primary-heading"
          class="
            min-w-0
            flex-1
            h-full
            flex flex-col
            overflow-hidden
            lg:order-last
          "
        >
          <h1 id="primary-heading" class="sr-only">Home</h1>
          <router-view :key="$route.path"></router-view>
        </section>
      </main>
    </div>
  </div>
  <SendSMS v-if="title !== 'SMS' && title !== 'Outreach'" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SideBar from "@/widgets/sidebar/SideBar.vue";
import TopBar from "@/widgets/topbar/TopBar.vue";
import SendSMS from "@/widgets/sms/SendSMS.vue";

export default {
  name: "Home",
  components: {
    SideBar,
    TopBar,
    SendSMS,
  },
  created() {
    this.listDIDs({ outreach: false });
    this.listRooms();
  },
  data() {
    return {
      title: this.$route?.meta?.title || "Internal Calls",
    };
  },
  methods: {
    ...mapActions("calls", ["listDIDs"]),
    ...mapActions("roomsStore", ["listRooms"]),
  },
  computed: {
    ...mapGetters("auth", ["userName"]),
  },
  watch: {
    $route(to) {
      this.title = to.meta.title || "Internal Calls";
      document.title = to.meta.title || "Internal Calls";
    },
  },
};
</script>
