import callsApi from "@/network/calls.js";

export const namespaced = true;

export const state = {
  isLoading: false,
  rooms: [],
  errorMessage: null,
};

export const mutations = {
  SET_ROOMS(state, rooms) {
    state.rooms = rooms;
  },

  ADD_NEW_ROOM(state, room) {
    state.rooms.unshift(room);
  },

  START_ROOM_RECORDING(state, bridgeId) {
    let room = state.rooms.filter((room) => room.bridgeId === bridgeId);
    if (room.length !== 0) {
      room[0].isRecording = true;
    }
  },

  STOP_ROOM_RECORDING(state, bridgeId) {
    let room = state.rooms.filter((room) => room.bridgeId === bridgeId);
    if (room.length !== 0) {
      room[0].isRecording = false;
    }
  },

  SET_ROOM_DATA(state, data) {
    let room = state.rooms.filter((room) => room.bridgeId === data.bridgeId);
    if (room.length !== 0) {
      room[0].channels = data.channels;
    }
  },

  REMOVE_CHANNEL_FROM_ROOM(state, { bridgeId, channelId }) {
    let room = state.rooms.find((room) => room.bridgeId === bridgeId);
    if (!room) return;
    let index = room.channels.indexOf(channelId);
    if (index > -1) {
      room.channels.splice(index, 1);
    }
  },

  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },

  SET_ERROR_MESSAGE(state, error) {
    state.errorMessage = error;
  },
};

export const actions = {
  async listRooms({ commit }) {
    commit("SET_ERROR_MESSAGE", null);
    commit("SET_LOADING", true);
    try {
      // const rooms = [];
      // const { data } = await callsApi.loadRoomDetails(
      //   "br_id_610bb6f66b9f81.73973782"
      // );
      // if (data === "404") {
      //   console.log("bridge not found");
      // } else {
      //   rooms.push(data);
      // }
      // commit("SET_ROOMS", rooms);
      // commit("SET_ROOMS", [
      //   {
      //     bridgeId: "br_id_60d48baa8df653.72088640",
      //     technology: "simple_bridge",
      //     bridge_type: "mixing",
      //     name: "Main Conference",
      //     channels: [],
      //     created_at: 1623077897,
      //     isRecording: false,
      //   },
      //   {
      //     bridgeId: "br_id_60d48beb374fa6.36719128",
      //     technology: "simple_bridge",
      //     bridge_type: "holding",
      //     name: "Holding",
      //     channels: [],
      //     created_at: 1623077950,
      //     isRecording: false,
      //   },
      // ]);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async create({ commit }, { roomName, roomType }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.createRoom(null, roomName, roomType);
      commit("ADD_NEW_ROOM", data);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async deleteRoom({ commit }, { roomID }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.deleteRoom(roomID);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async loadData({ commit }, { bridgeId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.loadRoomDetails(bridgeId);
      commit("SET_ROOM_DATA", data);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async startRecording({ commit }, { bridgeId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      //await callsApi.startRoomRecording(bridgeId);
      commit("START_ROOM_RECORDING", bridgeId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async stopRecording({ commit }, { bridgeId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      //await callsApi.stopRoomRecording(bridgeId);
      commit("STOP_ROOM_RECORDING", bridgeId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async removeChannel({ commit }, { bridgeId, channelId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.removeChannel(bridgeId, channelId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async apiAddChannel({ commit }, { bridgeId, channelId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.addChannel(bridgeId, channelId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  deleteChannel({ commit }, { bridgeId, channelId }) {
    commit("REMOVE_CHANNEL_FROM_ROOM", { bridgeId, channelId });
  },

  addChannel({ commit, state }, { bridgeId, channelId }) {
    commit("SET_ERROR_MESSAGE", null);
    let room = state.rooms.find((room) => room.bridgeId === bridgeId);
    room?.channels?.unshift(channelId);
  },
};

export const getters = {
  roomsList: (state) => state.rooms,
  isLoading: (state) => state.isLoading,
};
