import auth from "@/network/auth.js";
import StorageUtil from "@/utils/LocalStorageUtil.js";
export const namespaced = true;

export const state = {
  loading: false,
  errors: [],
  authData: null,
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_AUTH_DATA(state, data) {
    state.authData = data;
    StorageUtil.setUserAuthData(data);
  },
  CLEAR_AUTH_DATA() {
    StorageUtil.removeUserAuthData();
    location.reload();
  },
  SET_ERROR_MESSAGES(state, errors) {
    state.errors = errors;
  },
};

export const actions = {
  async login({ commit }, { email, password }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR_MESSAGES", []);
    try {
      const { data } = await auth.login(email, password);
      commit("SET_AUTH_DATA", data);
      return true;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGES",
        error.response?.data?.errors ?? [["Something went wrong"]]
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
  logout({ commit }) {
    commit("CLEAR_AUTH_DATA");
  },
};

export const getters = {
  loading: (state) => state.loading,
  authData: (state) => state.authData,
  errors: (state) => state.errors,
  userName: (state) => state.authData?.name,
};
