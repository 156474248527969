export default class TimeDateUtils {
  static secondsToMinutesAndSeconds = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;
    return ("0" + minutes).substr(-2) + ":" + ("0" + seconds).substr(-2);
  };

  static timestampToDate = (time) => {
    let date = new Date(time * 1000);
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ][date.getMonth()];
    let day = date.getDate();

    return day + ", " + month;
  };

  static timestampToTime = (time) => {
    let date = new Date(time * 1000);
    let hour = "0" + date.getHours();
    let min = "0" + date.getMinutes();

    return hour.substr(-2) + ":" + min.substr(-2);
  };
}
