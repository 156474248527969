import voicemailApi from "@/network/refactored/voicemail.js";

export const namespaced = true;

export const state = {
  voicemails: [],
  page: 1,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_VOICE_MAILS(state, voicemails) {
    state.voicemails = voicemails;
  },

  REMOVE_VOICE_MAIL(state, id) {
    state.voicemails = state.voicemails.filter((vm) => vm.id !== id);
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listVoicemails({ commit, state }, filter = {}) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      filter.page = state.page;
      const { data } = await voicemailApi.list(filter);
      commit("SET_VOICE_MAILS", data.data);
      commit("SET_TOTALS", data.total);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  removeVoicemail({ commit }, { id }) {
    commit("REMOVE_VOICE_MAIL", id);
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },
};

export const getters = {
  voicemails: (state) => state.voicemails,
  page: (state) => state.page,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
