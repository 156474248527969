<template>
  <div class="flex">
    <div class="relative inline-flex shadow-sm rounded-md">
      <Menu as="span" class="relative block" v-if="isConnected">
        <MenuButton
          :disabled="isLoading"
          class="
            h-11
            relative
            inline-flex
            items-center
            px-2
            py-2
            rounded-l-md
            text-sm
            font-medium
            text-white
            bg-gray-800
            hover:bg-gray-700
            focus:outline-none
          "
        >
          <span class="sr-only">Open options</span>
          <AdjustmentsIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="
              z-50
              origin-top-right
              absolute
              right-0
              mt-2
              -mr-1
              w-64
              rounded-md
              shadow-lg
              bg-white
              ring-1 ring-black ring-opacity-5
              focus:outline-none
            "
          >
            <div class="p-4">
              <div class="flex items-center space-x-3 pr-2">
                <button
                  @click="toggleSpeaker"
                  title="Mute speaker"
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-3
                    py-2
                    border border-transparent
                    text-xs
                    font-medium
                    rounded
                    text-indigo-700
                    bg-indigo-100
                    hover:bg-indigo-200
                    focus:outline-none
                  "
                >
                  <VolumeUpIcon
                    v-if="!speakerMuted"
                    class="h-4 w-4"
                    aria-hidden="true"
                  />
                  <VolumeOffIcon
                    v-if="speakerMuted"
                    class="h-4 w-4"
                    aria-hidden="true"
                  />
                </button>
                <Slider
                  @change="speakerVolumeUpdated"
                  :tooltips="false"
                  class="w-full"
                  v-model="speakerVolume"
                />
              </div>
              <div class="mt-2">
                <select
                  :disabled="!isConnected"
                  @change="speakerChanged"
                  v-model="selectedSpeaker"
                  class="
                    w-full
                    h-11
                    block
                    pl-3
                    pr-10
                    py-2
                    text-base
                    border-gray-300
                    focus:outline-none
                    sm:text-sm
                    rounded-md
                  "
                >
                  <option
                    v-for="speaker in speakers"
                    :key="speaker.groupId"
                    :value="speaker"
                  >
                    {{ speaker.label }}
                  </option>
                </select>
              </div>

              <div class="w-full h-px bg-gray-100 my-3"></div>

              <div class="flex items-center space-x-3 pr-2">
                <button
                  @click="toggleMicrophone"
                  title="Mute microphone"
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-3
                    py-2
                    border border-transparent
                    text-xs
                    font-medium
                    rounded
                    text-indigo-700
                    bg-indigo-100
                    hover:bg-indigo-200
                    focus:outline-none
                  "
                >
                  <StatusOnlineIcon
                    v-if="!microphoneMuted"
                    class="h-4 w-4"
                    aria-hidden="true"
                  />
                  <StatusOfflineIcon
                    v-if="microphoneMuted"
                    class="h-4 w-4"
                    aria-hidden="true"
                  />
                </button>
                <Slider
                  @change="micVolumeUpdated"
                  :tooltips="false"
                  class="w-full"
                  v-model="miceVolume"
                />
              </div>
              <div class="mt-2">
                <select
                  :disabled="!isConnected"
                  @change="micChanged"
                  v-model="selectedMicrophone"
                  class="
                    w-full
                    h-11
                    block
                    pl-3
                    pr-10
                    py-2
                    text-base
                    border-gray-300
                    focus:outline-none
                    sm:text-sm
                    rounded-md
                  "
                >
                  <option
                    v-for="mic in microphones"
                    :key="mic.groupId"
                    :value="mic"
                  >
                    {{ mic.label }}
                  </option>
                </select>
              </div>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <button
        @click="connect"
        type="button"
        class="
          relative
          inline-flex
          items-center
          px-4
          py-2
          shadow-sm
          text-sm
          font-medium
          text-white
          bg-gray-800
          hover:bg-gray-700
          focus:outline-none
        "
      >
        <LogoutIcon
          v-if="!isConnected"
          class="mr-1 h-5 w-5"
          aria-hidden="true"
        />
        <LoginIcon v-if="isConnected" class="mr-1 h-5 w-5" aria-hidden="true" />
        {{ !isConnected ? "Connect" : "Disconnect" }}
        ({{ selectedRoom.name ?? (rooms[0] ? rooms[0].name : "") }})
        <div class="ml-3">
          <span
            v-if="isConnected"
            class="
              animate-ping
              absolute
              right-3
              top-2.5
              inline-flex
              h-4
              w-4
              rounded-full
              bg-green-500
              opacity-75
            "
          ></span>
          <span
            :class="[
              isConnected ? 'bg-green-500' : 'bg-red-500',
              'block h-2 w-2 rounded-full ring-2 ring-whit',
            ]"
          />
        </div>
      </button>
      <button
        class="relative block"
        v-if="isLoading || isConnected"
        @click="connect"
      >
        <span
          class="
            h-11
            relative
            inline-flex
            items-center
            px-2
            py-2
            rounded-r-md
            text-sm
            font-medium
            text-white
            bg-gray-800
            hover:bg-gray-700
            focus:outline-none
          "
        >
          <StopIcon class="h-5 w-5" aria-hidden="true" />
        </span>
      </button>
      <Menu as="span" class="relative block" v-if="!isLoading && !isConnected">
        <MenuButton
          :disabled="isLoading || isConnected"
          class="
            h-11
            relative
            inline-flex
            items-center
            px-2
            py-2
            rounded-r-md
            text-sm
            font-medium
            text-white
            bg-gray-800
            hover:bg-gray-700
            focus:outline-none
          "
        >
          <span class="sr-only">Open options</span>
          <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="
              z-50
              origin-top-right
              absolute
              right-0
              mt-2
              -mr-1
              w-56
              rounded-md
              shadow-lg
              bg-white
              ring-1 ring-black ring-opacity-5
              focus:outline-none
            "
          >
            <div class="py-1">
              <MenuItem
                v-for="room in rooms"
                :key="room.bridgeId"
                v-slot="{ active }"
                @click="roomSelected(room)"
              >
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm',
                  ]"
                >
                  {{ room.name }}
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </div>

    <audio ref="remoteAudio" hidden controls class="hidden">
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { LogoutIcon, LoginIcon } from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import Slider from "@vueform/slider";
import JsSIP from "jssip";

import {
  ChevronDownIcon,
  VolumeUpIcon,
  VolumeOffIcon,
  StatusOnlineIcon,
  StatusOfflineIcon,
  AdjustmentsIcon,
  StopIcon,
} from "@heroicons/vue/solid";
import authApi from "@/network/auth.js";
var phone = null;
var session2 = null;
var rtcCall = null;

export default {
  components: {
    LogoutIcon,
    LoginIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    StopIcon,
    VolumeUpIcon,
    VolumeOffIcon,
    StatusOnlineIcon,
    StatusOfflineIcon,
    AdjustmentsIcon,
    Slider,
  },

  data() {
    return {
      speakers: [],
      speakerMuted: false,
      selectedSpeaker: null,

      microphones: [],
      microphoneMuted: false,
      selectedMicrophone: null,

      isLoading: false,
      isConnected: false,
      selectedRoom: {},
      phone2: null,
      session: null,
      callAnswered: false,
      incomingCalls: [],

      speakerVolume: 100,
      miceVolume: 100,
    };
  },

  async created() {
    window.addEventListener("beforeunload", () => {
      if (this.isConnected) {
        this.disconnect();
        //event.preventDefault();
        //event.returnValue = "";
        return;
      }
      return;
    });

    try {
      await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      this.loadDevices();
    } catch {
      alert("Permissions Denied");
    }
  },

  methods: {
    accept(index) {
      const session = this.incomingCalls[index];
      session.answer({
        mediaConstraints: {
          audio: true,
          video: false,
        },
      });
      this.incomingCalls.splice(index, 1);
    },
    reject(index) {
      const session = this.incomingCalls[index];
      session.terminate();
      this.incomingCalls.splice(index, 1);
    },

    async loadDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      devices.forEach((device) => {
        if (device.kind === "audioinput") {
          this.microphones.push(device);
        } else if (device.kind === "audiooutput") {
          this.speakers.push(device);
        }
      });
      this.selectedSpeaker = this.speakers[0];
      this.selectedMicrophone = this.microphones[0];
    },

    toggleSpeaker() {
      this.$refs.remoteAudio.muted = !this.speakerMuted;
      this.speakerMuted = !this.speakerMuted;
      if (this.speakerMuted) {
        this.speakerVolume = 0;
      } else {
        this.speakerVolume = 100;
        this.$refs.remoteAudio.volume = 1;
      }
    },

    speakerVolumeUpdated(value) {
      this.speakerMuted = value == 0;
      this.$refs.remoteAudio.volume = value / 100;
    },

    micVolumeUpdated(value) {
      this.microphoneMuted = value == 0;
    },

    speakerChanged() {
      this.$refs.remoteAudio.setSinkId(this.selectedSpeaker.deviceId);
    },

    micChanged() {
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((stream) => {
          window.localStream = stream; // A
          this.$refs.remoteAudio.srcObject = stream; // B
          // this.$refs.remoteAudio.autoplay = true; // C
          // console.log(stream);
        })
        .catch((err) => {
          console.log("u got an error:" + err);
        });
      this.$refs.remoteAudio.src = this.selectedMicrophone;
      // console.log(this.$refs.remoteAudio.captureStream());
      // this.$refs.remoteAudio.audio(this.selectedMicrophone.deviceId);
      // console.log(this.$refs.remoteAudio);
    },

    toggleMicrophone() {
      if (session2) {
        if (this.microphoneMuted) {
          session2?.unmute();
        } else {
          session2?.mute();
        }
      }
      this.microphoneMuted = !this.microphoneMuted;
      if (this.microphoneMuted) {
        this.miceVolume = 0;
      } else {
        this.miceVolume = 100;
      }
    },

    roomSelected(room) {
      this.selectedRoom.bridgeId = room.bridgeId;
      this.selectedRoom.name = room.name;
    },

    async connect() {
      const { data } = await authApi.loadProfile();
      if (this.isLoading) return;

      if (this.isConnected) {
        this.disconnect();
        return;
      }

      this.isLoading = true;
      this.$Progress.start();

      JsSIP.debug.enable("JsSIP:*");

      const socket = new JsSIP.WebSocketInterface(
        process.env.VUE_APP_WEBRTC_SERVER //data.webrtc.server //"wss://v99.own.ag:8089/owg/ws"
      );

      const configuration = {
        sockets: [socket],
        uri: `sip:${data.webrtc.user}@v99.own.ag`, //`"sip:${data.webrtc.user}@v99.own.ag"`, //"sip:webrtc_client@v99.own.ag",
        password: data.webrtc.pass, //data.webrtc.pass, //"webrtc_client",
        stun_servers: "stun:stun.own.ag:443",
        turn_servers: {
          urls: "turns:turn.own.ag:443",
          username: "FWjWFPLvYNUhJH7w",
          credential: "9YbGrjdLPe6Cpnsu",
        },
      };

      phone = new JsSIP.UA(configuration);

      phone.on("registered", () => {
        this.makeCall(data.name, data.webrtc.user);
      });

      phone.on("newRTCSession", (data) => {
        const session = data.session;
        if (session.direction === "incoming") {
          session2 = session;

          const self = this;
          session.on("peerconnection", (e) => {
            const peerconnection = e.peerconnection;
            peerconnection.onaddstream = (e) => {
              self.$refs.remoteAudio.srcObject = e.stream;
              self.$refs.remoteAudio.play();
            };
            const remoteStream = new MediaStream();
            peerconnection.getReceivers().forEach(function (receiver) {
              remoteStream.addTrack(receiver.track);
            });
          });

          if (!this.callAnswered) {
            session.answer({
              mediaConstraints: {
                audio: true,
                video: false,
              },
            });
            this.callAnswered = true;
          } else {
            this.addSession({ session });
            this.incomingCalls.push(session);
            const audio = new Audio("tune.mp3");
            audio.play();
          }
        }
      });

      phone.start();
    },

    async makeCall(userName, number) {
      let result = await this.placeCall({
        number: number, //"webrtc_client",
        callerIdNumber: null,
        callerIdName: userName,
        bridgeId: this.selectedRoom.bridgeId ?? this.rooms[0]?.bridgeId,
        local: true,
        record: false,
      });
      rtcCall = result;
      this.appendCall({ call: result });
      this.isLoading = false;
      this.$Progress.finish();
      this.isConnected = true;
      this.toggleExpand({
        bridgeId: this.selectedRoom.bridgeId ?? this.rooms[0]?.bridgeId,
        expanded: true,
      });
    },

    disconnect() {
      this.isConnected = false;
      this.callAnswered = false;
      phone.stop();
      this.$refs.remoteAudio.srcObject = null;
      this.$refs.remoteAudio.pause();
    },

    ...mapActions("place", ["placeCall"]),
    ...mapActions("activeCalls", ["appendCall"]),
    ...mapActions("incomingCalls", ["addSession"]),
    ...mapActions("roomsStore", ["toggleExpand"]),
  },
  computed: {
    ...mapGetters("roomsStore", ["rooms"]),
    ...mapGetters("activeCalls", ["calls"]),
  },
  watch: {
    calls: {
      handler(newValue) {
        if (
          this.isConnected &&
          !newValue.find((call) => call.id === rtcCall?.id)
        )
          this.disconnect();
      },
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
