<template>
  <router-link
    :title="label"
    :to="to"
    exact-active-class="bg-gray-900 text-white hover:bg-gray-900"
    class="
      text-gray-400
      hover:bg-gray-700
      flex-shrink-0
      inline-flex
      items-center
      justify-center
      h-14
      w-14
      rounded-lg
    "
  >
    <span class="sr-only">{{ label }}</span>

    <svg
      class="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <slot></slot>
    </svg>
  </router-link>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "/",
    },
  },
};
</script>

<style></style>
