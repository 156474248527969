import axiosInstance, {
  INBOUNDS_URL,
  ENDPOINTS_URL,
  RESTORE_INBOUND_URL,
} from "../config.js";

export default {
  listEndpoints() {
    return axiosInstance.get(`${ENDPOINTS_URL}?limit=1000`);
  },

  listInbounds(deletedOnly, page) {
    let url = `${INBOUNDS_URL}?page=${page}`;
    if (deletedOnly) {
      url += "&deleted=1";
    }
    return axiosInstance.get(url);
  },

  createInbound(
    did_number,
    forward_to_number,
    description,
    direct_to_voicemail,
    ring_timeout,
    timeout_action,
    endpoint_id,
    service_type,
    outreach
  ) {
    return axiosInstance.post(`${INBOUNDS_URL}`, {
      did_number,
      forward_to_number,
      description,
      direct_to_voicemail,
      ring_timeout,
      timeout_action,
      endpoint_id,
      service_type,
      outreach,
    });
  },

  update(
    id,
    did_number,
    forward_to_number,
    description,
    direct_to_voicemail,
    ring_timeout,
    timeout_action,
    endpoint_id,
    service_type,
    outreach
  ) {
    return axiosInstance.patch(`${INBOUNDS_URL}/${id}`, {
      did_number,
      forward_to_number,
      description,
      direct_to_voicemail,
      ring_timeout,
      timeout_action,
      endpoint_id,
      service_type,
      outreach,
    });
  },

  delete(id) {
    return axiosInstance.delete(`${INBOUNDS_URL}/${id}`);
  },

  restore(id) {
    return axiosInstance.get(`${RESTORE_INBOUND_URL}/${id}`);
  },
};
