import { createRouter, createWebHistory } from "vue-router";
import StorageUtil from "@/utils/LocalStorageUtil.js";
import Home from "../views/Home.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requireAuth: true, title: "Live Calls" },
    redirect: "/live",
    children: [
      {
        path: "/live",
        name: "LiveCall",
        meta: { title: "Live Call" },
        component: () => import("../views/livecall/LiveCall.vue"),
      },
      {
        path: "/users",
        name: "Users",
        meta: { title: "Users" },
        component: () => import("../views/users/Users.vue"),
      },
      {
        path: "/user/:id",
        name: "EditUser",
        meta: { title: "Edit User" },
        component: () => import("../views/users/Edit.vue"),
      },
      {
        path: "/users/add",
        name: "AddUser",
        meta: { title: "Add New User" },
        component: () => import("../views/users/Add.vue"),
      },
      {
        path: "/settings",
        name: "Settings",
        meta: { title: "Settings" },
        component: () => import("../views/Settings.vue"),
      },
      {
        path: "/recording",
        name: "Recordings",
        meta: { title: "Recordings" },
        component: () => import("../modules/recordings/recordings.vue"),
      },
      {
        path: "/session/:id",
        name: "Session",
        meta: { title: "Session" },
        component: () => import("../modules/recordings/sessions.vue"),
      },
      {
        path: "/inbounds",
        name: "Inbounds",
        meta: { title: "Inbounds" },
        component: () => import("../modules/inbounds/inbounds.vue"),
      },
      {
        path: "/devices",
        name: "Devices",
        meta: { title: "Devices" },
        component: () => import("../modules/end_points/end_points.vue"),
      },
      {
        path: "/voicemail",
        name: "Voice Mail",
        meta: { title: "Voice Mail" },
        component: () => import("../modules/voicemail/voicemail.vue"),
      },
      {
        path: "/sms",
        name: "SMS",
        meta: { title: "SMS" },
        props: { outreach: 0 },
        component: () => import("../views/sms/SMS.vue"),
      },
      {
        path: "/outreach",
        name: "Outreach",
        meta: { title: "Outreach" },
        props: { outreach: 1 },
        component: () => import("../views/sms/SMS.vue"),
      },
      {
        path: "/queue",
        name: "Queues",
        meta: { title: "Queues" },
        component: () => import("../modules/queues/queues.vue"),
      },
      {
        path: "/queue/:id",
        name: "Queue Calls",
        meta: { title: "Queue Calls" },
        component: () => import("../modules/queues/queue_calls.vue"),
      },
      {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        meta: { title: "Not Found" },
        component: () => import("../views/PageNotFound.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: { noRequireAuth: true },
    component: () => import("../views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to.name);
  const loggedIn = StorageUtil.getUserAuthData();

  if (to.matched.some((record) => record.meta.requireAuth) && !loggedIn) {
    next("/login");
  } else if (
    to.matched.some((record) => record.meta.noRequireAuth) &&
    loggedIn
  ) {
    next("/");
  } else {
    const userType = StorageUtil.getUserType();
    if (userType === "listen_only" && to.name !== "LiveCall") {
      next("/live");
    } else if (
      userType === "conference_manager" &&
      to.name !== "LiveCall" &&
      to.name !== "Recordings" &&
      to.name !== "Inbounds" &&
      to.name !== "Voice Mail" &&
      to.name !== "Session" &&
      to.name !== "SMS" &&
      to.name !== "Outreach" &&
      to.name !== "Queues" &&
      to.name !== "Queue Calls"
    ) {
      console.log(userType, to.name);
      next("/live");
    } else {
      next();
    }
  }
});

export default router;
