//import "ion-sound";

import callsApi from "@/network/refactored/incoming_calls";

export const namespaced = true;

export const state = {
  sessions: [
    // {
    //   number: "123123123",
    //   caller_id_num: "32131321",
    // },
  ],
  incomingMuted: false,
};

export const mutations = {
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
  },
  ADD_SESSION(state, session) {
    state.sessions.unshift(session);
  },
  REMOVE_SESSION(state, session) {
    console.log("removing call");
    const ses = state.sessions.find(
      (call) => call.channel_id === session.channel_id
    );
    const index = state.sessions.indexOf(ses);
    if (index > -1) {
      state.sessions.splice(index, 1);
    }
  },
  SET_MUTE(state, value) {
    state.incomingMuted = value;
  },
};

export const actions = {
  addSession({ commit, state }, { session }) {
    if (!state.incomingMuted) {
      if (!window.ion) {
        import("ion-sound").then(() => {
          window.ion.sound({
            sounds: [
              {
                name: "tune",
              },
              {
                name: "sms",
              },
            ],
            volume: 1,
            path: "https://v88-assets.s3.us-east-2.amazonaws.com/",
            preload: true,
          });
          window.ion.sound.play("tune");
        });
      } else {
        window.ion.sound.play("tune");
      }
      //window.ion.sound.play("tune");
      // const audio = new Audio("tune.mp3");
      // audio.play();
    }
    commit("ADD_SESSION", session);
  },

  removeSession({ commit }, { session }) {
    commit("REMOVE_SESSION", session);
  },

  setMute({ commit }, { value }) {
    commit("SET_MUTE", value);
  },

  async listCalls({ commit }) {
    try {
      const { data } = await callsApi.listCalls();
      commit("SET_SESSIONS", data);
    } catch (error) {
      console.log(error);
    }
  },
};

export const getters = {
  sessions: (state) => state.sessions,
  incomingMuted: (state) => state.incomingMuted,
};
