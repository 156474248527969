import { createApp } from "vue";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VCalendar from "v-calendar";
import { SnackbarPlugin } from "snackbar-vue";
import "snackbar-vue/dist/snackbar-vue.common.css";
import Rollbar from "rollbar";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";

import mitt from "mitt";
const emitter = mitt();

const rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.VUE_APP_ENV,
  },
});

const progressBarOptions = {
  color: "#F59E0B",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VCalendar, {})
  .use(SnackbarPlugin)
  .use(VueProgressBar, progressBarOptions);
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$rollbar = rollbar;
app.mount("#app");
