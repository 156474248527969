import inboundsApi from "@/network/refactored/inbounds.js";

export const namespaced = true;

export const state = {
  inbounds: [],
  page: 1,
  deletedOnly: false,
  totals: 0,
  loading: false,
  error: null,
};

export const mutations = {
  SET_INBOUNDS(state, inbounds) {
    state.inbounds = inbounds;
  },

  ADD_INBOUNDS(state, inbound) {
    state.inbounds.unshift(inbound);
  },

  REMOVE_INBOUNDS(state, id) {
    state.inbounds = state.inbounds.filter((inb) => inb.id !== id);
  },

  UPDATE_INBOUNDS(state, inbound) {
    const i = state.inbounds.findIndex((inb) => inb.id === inbound.id);
    if (state.inbounds[i]) {
      state.inbounds[i] = inbound;
    }
  },

  SET_PAGE(state, value) {
    state.page = value;
  },

  SET_DELETED_ONLY(state, value) {
    state.deletedOnly = value;
  },

  SET_TOTALS(state, value) {
    state.totals = value;
  },

  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async listInbounds({ commit, state }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR", null);
    try {
      const { data } = await inboundsApi.listInbounds(
        state.deletedOnly,
        state.page
      );
      commit("SET_INBOUNDS", data.data);
      commit("SET_TOTALS", data.total);
    } catch (error) {
      commit(
        "SET_ERROR",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  addInbound({ commit }, { inbound }) {
    commit("ADD_INBOUNDS", inbound);
  },

  updateInbound({ commit }, { inbound }) {
    commit("UPDATE_INBOUNDS", inbound);
  },

  removeInbound({ commit }, { inboundId }) {
    commit("REMOVE_INBOUNDS", inboundId);
  },

  updatePage({ commit }, { page }) {
    commit("SET_PAGE", page);
  },

  updateDeletedOnly({ commit }, { deletedOnly }) {
    commit("SET_PAGE", 1);
    commit("SET_DELETED_ONLY", deletedOnly);
  },
};

export const getters = {
  inbounds: (state) => state.inbounds,
  page: (state) => state.page,
  deletedOnly: (state) => state.deletedOnly,
  totals: (state) => state.totals,
  loading: (state) => state.loading,
  error: (state) => state.error,
};
