import axiosInstance, { RECORDING_URL } from "./config.js";

export default {
  list({ from, to, callId, number }) {
    let params = "";
    if (from) {
      params += "filters[date_from]=" + from;
    }
    if (to) {
      params += "&filters[date_to]=" + to;
    }
    if (callId) {
      params += "&filters[call_id]=" + callId;
    }
    if (number) {
      params += "&filters[number]=" + number;
    }
    return axiosInstance.get(RECORDING_URL + "?" + params);
  },
};
