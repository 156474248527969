import recordingApi from "@/network/recording.js";

export const namespaced = true;

export const state = {
  recordings: [],
  errorMessage: null,
  isLoading: false,
};

export const mutations = {
  SET_RECORDINGS(state, data) {
    state.recordings = data;
  },

  SET_ERROR_MESSAGE(state, error) {
    state.errorMessage = error;
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },
};

export const actions = {
  async list({ commit }, filter) {
    commit("SET_ERROR_MESSAGE", null);
    commit("SET_LOADING", true);
    try {
      const { data } = await recordingApi.list(filter);
      const calls = [];
      data.data.forEach((rec) => {
        const call = {
          id: rec.call.id,
          number: rec.call.number,
          callerId: rec.call.caller_id_num,
          callerName: rec.call.caller_id_name,
          record: rec.link,
          download: rec.download_link,
          rec_name: rec.recording_name,
        };
        calls.push(call);
      });

      const result = calls
        .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
        .map((call) => {
          return {
            id: call.id,
            number: call.number,
            callerName: call.callerName,
            callerId: call.callerId,
          };
        });

      result.forEach((res) => {
        res.allRecordings = calls
          .filter((call) => call.id === res.id)
          .map((call) => {
            return {
              rec_name: call.rec_name,
              record: call.record,
              download: call.download,
            };
          });
      });

      commit("SET_RECORDINGS", result);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export const getters = {
  recordings: (state) => state.recordings,
  isLoading: (state) => state.isLoading,
};
