<template>
  <nav
    aria-label="Sidebar"
    class="block flex-shrink-0 bg-gray-800 overflow-y-auto"
  >
    <div class="relative w-20 flex flex-col p-3 space-y-1">
      <SideBarLink label="Live" to="/live">
        <path
          d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
        />
      </SideBarLink>

      <SideBarLink
        v-if="userType !== 'listen_only'"
        label="Recording"
        to="/recording"
      >
        <path
          d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
        ></path>
      </SideBarLink>

      <SideBarLink
        v-if="userType !== 'listen_only'"
        label="Inbounds"
        to="/inbounds"
      >
        <path
          d="M14.414 7l3.293-3.293a1 1 0 00-1.414-1.414L13 5.586V4a1 1 0 10-2 0v4.003a.996.996 0 00.617.921A.997.997 0 0012 9h4a1 1 0 100-2h-1.586z"
        ></path
        ><path
          d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
        ></path>
      </SideBarLink>

      <SideBarLink v-if="userType === 'admin'" label="Devices" to="/devices">
        <path
          fill-rule="evenodd"
          d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
          clip-rule="evenodd"
        ></path>
      </SideBarLink>

      <SideBarLink v-if="userType === 'admin'" label="Users" to="/users">
        <path
          d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
        />
      </SideBarLink>

      <!-- <SideBarLink label="Caller ID" to="/caller">
        <path
          fill-rule="evenodd"
          d="M10 2a1 1 0 00-1 1v1a1 1 0 002 0V3a1 1 0 00-1-1zM4 4h3a3 3 0 006 0h3a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2zm2.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm2.45 4a2.5 2.5 0 10-4.9 0h4.9zM12 9a1 1 0 100 2h3a1 1 0 100-2h-3zm-1 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
          clip-rule="evenodd"
        />
      </SideBarLink> -->

      <SideBarLink
        v-if="userType !== 'listen_only'"
        label="Voice Mail Messages"
        to="/voicemail"
      >
        <path
          fill-rule="evenodd"
          d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
          clip-rule="evenodd"
        ></path>
      </SideBarLink>

      <SideBarLink v-if="userType !== 'listen_only'" label="SMS" to="/sms">
        <MailIcon />
      </SideBarLink>

      <SideBarLink
        v-if="
          (userType !== 'listen_only' &&
            userType === 'conference_manager' &&
            isOutreach === 1) ||
          userType === 'admin'
        "
        label="Outreach"
        to="/outreach"
      >
        <ChatAltIcon />
      </SideBarLink>

      <SideBarLink v-if="userType !== 'listen_only'" label="Queue" to="/queue">
        <ClockIcon />
      </SideBarLink>

      <!-- <SideBarLink label="Conference" to="/conference">
        <path
          d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"
        />
        <path
          d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"
        />
      </SideBarLink> -->

      <SideBarLink v-if="userType === 'admin'" label="Settings" to="/settings">
        <path
          fill-rule="evenodd"
          d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
          clip-rule="evenodd"
        />
      </SideBarLink>
    </div>
  </nav>
</template>

<script>
import SideBarLink from "@/widgets/sidebar/SideBarLink.vue";
import StorageUtil from "@/utils/LocalStorageUtil.js";
import { ClockIcon, MailIcon, ChatAltIcon } from "@heroicons/vue/solid";
export default {
  components: {
    SideBarLink,
    MailIcon,
    ClockIcon,
    ChatAltIcon,
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    isOutreach() {
      return StorageUtil.getUserData()?.outreach;
    },
  },
};
</script>

<style></style>
