import axiosInstance, { VOICE_MAIL_URL } from "../config.js";

export default {
  list({ from, to, search, duration, page }) {
    let params = `page=${page}`;
    if (from) {
      params += "&date_gt=" + from;
    }
    if (to) {
      params += "&date_lt=" + to;
    }
    if (search) {
      params += "&search=" + search;
    }
    if (duration) {
      params += "&duration_gt=" + duration;
    }
    return axiosInstance.get(`${VOICE_MAIL_URL}?${params}`);
  },

  delete(id) {
    return axiosInstance.delete(`${VOICE_MAIL_URL}/${id}`);
  },
};
