import axiosInstance, { USERS_URL } from "./config.js";

export default {
  listUsers(page) {
    return axiosInstance.get(`${USERS_URL}?page=${page}`);
  },
  getUser(userId) {
    return axiosInstance.get(`${USERS_URL}/${userId}`);
  },
  addUser(
    name,
    email,
    phone_number,
    password,
    permission,
    additional_permission
  ) {
    console.log(
      name,
      email,
      phone_number,
      password,
      permission,
      additional_permission
    );
    return axiosInstance.post(`${USERS_URL}`, {
      name,
      email,
      password,
      phone_number,
      user_type: permission,
      outreach: additional_permission,
    });
  },
  deleteUser(userId) {
    return axiosInstance.delete(`${USERS_URL}/${userId}`);
  },
  updateUser(
    userId,
    name,
    email,
    phone_number,
    password,
    permission,
    additional_permission
  ) {
    let data = {
      name,
      email,
      phone_number,
      user_type: permission,
      outreach: additional_permission,
    };
    if (password) {
      data["password"] = password;
    }
    return axiosInstance.patch(`${USERS_URL}/${userId}`, data);
  },
};
