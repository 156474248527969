import callsApi from "@/network/calls.js";
import Pusher from "pusher-js";
import StorageUtil from "@/utils/LocalStorageUtil.js";

export const namespaced = true;

export const state = {
  isLoading: false,
  activeCalls: [],
  recentCalls: [],
  missedCalls: [],
  dmtfNumber: null,
  errorMessage: null,

  pusher: null,
};

export const mutations = {
  SET_ACTIVE_CHANNELS(state, channels) {
    state.activeCalls = channels;
  },

  SET_RECENT_CHANNELS(state, channels) {
    state.recentCalls = channels;
  },

  SET_MISSED_CHANNELS(state, channels) {
    state.missedCalls = channels;
  },

  SET_MUTE_CALL(state, { callId, status }) {
    let call = state.activeCalls.filter((call) => call.id === callId)[0];
    call.isMuted = status;
  },

  SET_PAUSE_RECORDING(state, { callId, status }) {
    let call = state.activeCalls.filter((call) => call.id === callId)[0];
    call.isRecordingPaused = status;
  },

  SET_MUTE_RECORDING(state, { callId, status }) {
    let call = state.activeCalls.filter((call) => call.id === callId)[0];
    call.isRecordingMuted = status;
  },

  SET_RECORDING_CALL(state, { callId, status }) {
    let call = state.activeCalls.find((call) => call.id === callId);
    call.recording_status = status ? "on" : "off";
  },

  REMOVE_CALL(state, callId) {
    let calls = state.activeCalls.filter((channel) => channel.id !== callId);
    state.activeCalls = calls;
  },

  SET_DTMF_NUM(state, dtmf) {
    state.dmtfNumber = dtmf;
  },

  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },

  SET_ERROR_MESSAGE(state, error) {
    state.errorMessage = error;
  },

  ADD_ACTIVE_CALL(state, call) {
    state.activeCalls.unshift(call);
  },
};

export const actions = {
  async listActiveCalls({ commit }) {
    commit("SET_LOADING", true);
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.activeCalls();
      const webRtcData = await callsApi.webrtcCalls();
      webRtcData.data.forEach((rtc) => {
        const toChannel = {
          id: rtc.call.id,
          bridge_id: rtc.call.bridge_id,
          call_status: rtc.call.call_status,
          caller_id_name: rtc.channel_name?.split("-")[1]?.substring(3),
          caller_id_num: rtc.call.caller_id_num,
          channel_id: rtc.call.channel_id,
          number: rtc.call.number,
          duration: 0,
          recording_status: rtc.call.recording_status,
          isWebRtc: true,
        };
        data.unshift(toChannel);
      });
      commit("SET_ACTIVE_CHANNELS", data);
      return webRtcData;
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    } finally {
      commit("SET_LOADING", false);
    }
  },

  async listRecentCalls({ commit }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.recentCalls();
      commit("SET_RECENT_CHANNELS", data.data);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async listMissedCalls({ commit }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      const { data } = await callsApi.missedCalls();
      commit("SET_MISSED_CHANNELS", data.data);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async muteCall({ commit }, { callId, status }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      if (status === true) {
        await callsApi.muteCall(callId, status);
      } else {
        await callsApi.unMuteCall(callId, status);
      }
      commit("SET_MUTE_CALL", { callId, status });
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async hangup({ commit }, { callId }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.hangupCall(callId);
      commit("REMOVE_CALL", callId);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  // Recordings

  async recordCall({ commit }, { callId, status }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      if (status === true) {
        await callsApi.startRecording(callId);
      } else {
        await callsApi.stopRecording(callId);
      }
      commit("SET_RECORDING_CALL", { callId, status });
      commit("SET_PAUSE_RECORDING", { callId, status: false });
      commit("SET_MUTE_RECORDING", { callId, status: false });
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async pauseRecording({ commit }, { callId, status }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      if (status === true) {
        await callsApi.pauseRecording(callId, status);
      } else {
        await callsApi.resumeRecording(callId, status);
      }
      commit("SET_PAUSE_RECORDING", { callId, status });
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  async muteRecording({ commit }, { callId, status }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      if (status === true) {
        await callsApi.muteRecording(callId, status);
      } else {
        await callsApi.unmuteRecording(callId, status);
      }
      commit("SET_MUTE_RECORDING", { callId, status });
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },
  // ------------

  async sendDTMF({ commit, state }, { dtmf }) {
    commit("SET_ERROR_MESSAGE", null);
    try {
      await callsApi.sendDTMF(state.dmtfNumber, dtmf);
    } catch (error) {
      commit(
        "SET_ERROR_MESSAGE",
        error?.response?.data?.message ?? "Something went wrong"
      );
    }
  },

  setDMTF({ commit }, { callId }) {
    commit("SET_DTMF_NUM", callId);
  },

  addActiveCall({ commit }, { data }) {
    commit("ADD_ACTIVE_CALL", data);
  },

  initPusher({ state }) {
    state.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      auth: {
        headers: { Authorization: `Bearer ${StorageUtil.getUserAuthToken()}` },
      },
      authEndpoint:
        "https://o8rtlddhcc.execute-api.us-east-2.amazonaws.com/api/broadcasting/auth",
    });
  },

  pusherBind({ state, dispatch }) {
    state.pusher.bind("App\\Events\\CallStatusEvent", (data) => {
      if (data.event) {
        const num = data.event.number;
        const status = data.event.status.split(".")[1];

        if (status === "Hangup") {
          const call = state.activeCalls.find((call) => call.number === num);
          if (call) {
            dispatch(
              "rooms/deleteChannel",
              { bridgeId: call.bridge_id, channelId: call.channel_id },
              { root: true }
            );
          }

          let calls = state.activeCalls.filter(
            (channel) => channel.number !== num
          );
          state.activeCalls = calls;
          dispatch("listRecentCalls");
        } else {
          state.activeCalls.forEach((channel) => {
            if (channel.number === num) {
              channel.call_status = status;
            }
          });
        }
      }
    });
  },

  subscribeToChannel({ state }, channelId) {
    state.pusher.subscribe(channelId + "-call-status");
  },

  changeRoom({ state }, { bridgeId, channelId }) {
    let call = state.activeCalls.find((call) => call.channel_id === channelId);
    call.bridge_id = bridgeId;
  },
};

export const getters = {
  isLoading: (state) => state.isLoading,
  activeCalls: (state) => state.activeCalls,
  recentCalls: (state) => state.recentCalls,
  missedCalls: (state) => state.missedCalls,
  dmtfNumber: (state) => state.dmtfNumber,
  activeCallById: (state) => (channelId) => {
    return state.activeCalls.find(
      (channel) => channel.channel_id === channelId
    );
  },
  isRoomRecording: (state) => (roomId) => {
    let room = state.activeCalls?.find(
      (channel) =>
        channel.bridge_id === roomId && channel.recording_status === "on"
    );
    return room ? true : false;
  },

  pusher: (state) => state.pusher,
};
